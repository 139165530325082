import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { TEvent } from '../types';

export const getEvent = async ({ eventId }: { eventId?: number }): Promise<TEvent> => {
  return axios.get(`/event/${eventId}`);
};

type QueryFnType = typeof getEvent;

type UseUserOptions = {
  eventId?: number;
  config?: QueryConfig<QueryFnType>;
};

export const useEvent = ({ eventId, config }: UseUserOptions) => {

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['events', eventId],
    queryFn: () => getEvent({ eventId }),
  });
};
