import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Input } from '@/components/ui/input';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { UserType } from '@/features/user';
import { LabelGroup } from '@/features/label/types';
import { Label } from '@/features/label/components/label';
import { useFormContext } from 'react-hook-form';
import { useMe } from '@/features/auth/api/get-me';

const DEFAULT_DIGITAL_SERVICES = ['Fil (HLS (m3u8), MP¤, WEBM)', 'Vimeo', 'Youtube', 'Annan'];

export function StreamingSection({ defaultCollapsed = false }: { defaultCollapsed?: boolean }) {
  const { data: user } = useMe({});
  const userType = user?.role;
  const form = useFormContext();

  const labelGroup = userType === UserType.ADMIN || userType === UserType.MANAGER ? LabelGroup.MANAGER_EVENT_HANDLING : LabelGroup.ORGANIZER_EVENT_FORM;

  return (
    <div className="space-y-6">
      <Card>
        <Accordion type="single" collapsible defaultValue={defaultCollapsed ? '' : 'streaming'}>
          <AccordionItem className="[&[data-state=open]>.card-header]:bg-gray-medium [&[data-state=open]>.card-header]:text-white [&[data-state=open]>.card-header]:rounded-t-md" value="streaming">
            <CardHeader className="py-3">
              <AccordionTrigger className="py-0 text-md">
                <CardTitle>
                  <Label name="event_streaming_title" groupName={LabelGroup.ORGANIZER_EVENT_FORM} />
                </CardTitle>
              </AccordionTrigger>
            </CardHeader>
            <AccordionContent>
              <CardContent className="pt-6 text-sm space-y-6">
                <p className="text-sm">
                  <Label name="event_streaming_description" groupName={LabelGroup.ORGANIZER_EVENT_FORM} />
                </p>
                <FormField
                  control={form.control}
                  name="digitalStreamingType"
                  render={({ field }) => (
                    <FormItem className=" space-y-2">
                      <FormLabel className="font-semibold">
                        <Label name="digital_streaming_type" groupName={labelGroup} />
                      </FormLabel>
                      <FormDescription className="mb-2  text-muted-foreground">
                        <Label name="digital_streaming_type_description" groupName={labelGroup} />
                      </FormDescription>
                      <Select onValueChange={field.onChange} value={field.value}>
                        <FormControl>
                          <SelectTrigger className=" [&>span]:text-left w-full max-w-96">
                            <SelectValue placeholder={<Label name="select_digital_streaming_type_placeholder" groupName={labelGroup} />} />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {DEFAULT_DIGITAL_SERVICES.sort().map(value => (
                            <SelectItem key={value} value={value}>
                              <Label name={'digital_streaming_type_option_' + value} groupName={labelGroup} />
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>

                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="digitalStreamingUrl"
                  render={({ field }) => (
                    <FormItem className=" space-y-2">
                      <FormLabel className="font-semibold">
                        <Label name="digital_streaming_url" groupName={labelGroup} />
                      </FormLabel>
                      <FormDescription className="text-muted-foreground">
                        <Label name="digital_streaming_url_description" groupName={labelGroup} />
                      </FormDescription>
                      <FormControl>
                        <Input {...field} className="w-full max-w-full" maxLength={100} />
                      </FormControl>
                      <FormDescription className="italic">
                        <Label name="you_have_letters_left" groupName={labelGroup} /> {100 - (field?.value?.length ?? 0)} <Label name="letters_left" groupName={labelGroup} />
                      </FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </CardContent>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </Card>
    </div>
  );
}
