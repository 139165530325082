import { useOrganizations } from '../api/getOrganizations';
import { OrganizationsShortcutCard } from '../components/organizations-shortcut-card';
import { EventsShortcutCard } from '../components/events-shortcut-card';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { DownloadProgramShortCut } from '../components/(download-program)/download-program-shortcut';
import { useMe } from '@/features/auth/api/get-me';
import { Skeleton } from '@/components/ui/skeleton';
import { Banner } from '@/features/misc/components/banner';

export function Dashboard() {
  const { data: user } = useMe({});

  const { data: organizations = [], isLoading } = useOrganizations({
    userId: user?.userId ?? 0,
    config: { enabled: !!user?.userId },
  });

  return (
    <div className="w-full h-full space-y-6">
      <h2 className="font-bold text-xl">
        <Label name="dashboard_title" groupName={LabelGroup.ORGANIZER_DASHBOARD} />
      </h2>
      <Banner />
      <div className="grid md:grid-cols-3 w-full gap-2">
        {isLoading ? (
          <>
            <Skeleton className=" h-72" />
            <Skeleton className=" h-72" />
            <Skeleton className=" h-72" />
          </>
        ) : (
          <>
            <OrganizationsShortcutCard />
            {organizations?.length > 0 && <EventsShortcutCard />}
            <DownloadProgramShortCut />
          </>
        )}
      </div>
      <div className='grid md:grid-cols-2 gap-2 home-section'>
        <Label name="dashboard_main_body" groupName={LabelGroup.ORGANIZER_DASHBOARD} />
        <Label name="dashboard_main_body2" groupName={LabelGroup.ORGANIZER_DASHBOARD} />
      </div>
      {/* {!hasNoEvents && !hasNoOrganizations && isEventsFetched && <OrganizerEventList />} */}
    </div>
  );
}
