import { cn } from '@/utils';
import { NavigationMenu, NavigationMenuItem, NavigationMenuLink, NavigationMenuList, navigationMenuTriggerStyle } from '@/components/ui/navigation-menu';
import { Link, useLocation } from 'react-router-dom';
import { useOrganizations } from '../api/getOrganizations';
import { OrganizationDropdown } from './OrganizationDropdown';
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import { DropdownMenuLabel } from '@/components/ui/dropdown-menu';
import { CalendarCheck, MenuIcon } from 'lucide-react';
import { usePayments } from '@/features/payment/api/get-payments';
import { useOrganizerStore } from '@/stores/organizerStore';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { UserProfileDropdown } from '@/features/user/components/user-profile-dropdown';
import { House, Receipt } from 'react-bootstrap-icons';
import { AlmdedalenIcon } from '@/components/almedalen-icon';
import { useMe } from '@/features/auth/api/get-me';
import { useEffect, useState } from 'react';

export function Menu() {
  const [open, setOpen] = useState(false);
  const { data: user } = useMe({});

  const { pathname } = useLocation();

  useEffect(() => {
    setOpen(false);
  }, [pathname]);

  const organizerId = useOrganizerStore(state => state.organizationId);

  const { data: payments = [] } = usePayments({ organizerId, config: { enabled: !!organizerId } });
  const { data: organizations = [] } = useOrganizations({
    userId: user?.userId ?? 0,
    config: { enabled: !!user?.userId }
  });

  return (
    <header className="w-full bg-gray border-b border-input flex items-center justify-between ">
      <div className={cn('max-w-[1440px] mx-auto gap-x-2 flex items-center lg:items-end justify-between w-full lg:px-6   lg:py-0 ')}>
        <Link to="/">
          <AlmdedalenIcon className="w-fit min-w-[107px] h-[120px] hidden lg:block" />
        </Link>
        <div className="flex lg:items-end items-center justify-between lg:justify-end space-x-2 px-6 lg:px-0  lg:space-x-6 md:space-x-4 w-full">
          <Sheet open={open} onOpenChange={isOpen => setOpen(isOpen)}>
            <SheetTrigger onClick={() => setOpen(!open)} className="block lg:hidden">
              <MenuIcon size={24} className="text-white" />
            </SheetTrigger>
            <SheetContent className="w-72">
              <div className="mt-8 mb-4">
                <OrganizationDropdown />
              </div>

              <div>
                <Link to="/">
                  <DropdownMenuLabel>
                    <Label name="start_menu_title" groupName={LabelGroup.ORGANIZER_MENU} />
                  </DropdownMenuLabel>
                </Link>
              </div>
              {organizations?.length > 0 && (
                <div>
                  <Link to="/events">
                    <DropdownMenuLabel>
                      <Label name="events_menu_title" groupName={LabelGroup.ORGANIZER_MENU} />
                    </DropdownMenuLabel>
                  </Link>
                </div>
              )}
              {/* {organizations?.length > 0 && (
                <div>
                  <Link to="/organizations">
                    <DropdownMenuLabel>
                      <Label name="organizations_menu_title" groupName={LabelGroup.ORGANIZER_MENU} />
                    </DropdownMenuLabel>
                  </Link>
                </div>
              )} */}
              {payments?.length > 0 && (
                <div>
                  <Link to="/payments">
                    <DropdownMenuLabel>
                      <Label name="payments_menu_title" groupName={LabelGroup.ORGANIZER_MENU} />
                    </DropdownMenuLabel>
                  </Link>
                </div>
              )}
            </SheetContent>
          </Sheet>
          <Link to="/">
            <AlmdedalenIcon className="w-12 h-full lg:hidden block" />
          </Link>
          <div className="hidden lg:flex bg-gray ">
            <NavigationMenu className="space-x-0 lg:block hidden w-full">
              <NavigationMenuList className="flex space-x-0 w-full  ">
                <NavigationMenuItem>
                  <Link to="/">
                    <NavigationMenuLink
                      active={window.location.pathname === '/'}
                      className={cn(
                        navigationMenuTriggerStyle(),
                        `bg-black/30  rounded-none  h-[60px]  px-8
                       text-white hover:bg-black/70 
                       hover:text-whited focus:bg-black/70
                        focus:text-white  data-[active]:bg-black/70
                         data-[active]:text-white data-[state=open]:bg-black/70`
                      )}>
                      <House size={20} className="mr-2" />
                      <Label name="start_menu_title" groupName={LabelGroup.ORGANIZER_MENU} />
                    </NavigationMenuLink>
                  </Link>
                </NavigationMenuItem>
                {organizations?.length > 0 && (
                  <NavigationMenuItem>
                    <Link to="/events">
                      <NavigationMenuLink
                        active={window.location.pathname === '/events'}
                        className={cn(
                          navigationMenuTriggerStyle(),
                          ` bg-black/30 rounded-none  h-[60px]     px-8
                       text-white hover:bg-black/70 
                       hover:text-whited focus:bg-black/70
                        focus:text-white  data-[active]:bg-black/70
                         data-[active]:text-white data-[state=open]:bg-black/70`
                        )}>
                        <CalendarCheck size={20} className="mr-2" />
                        <Label name="events_menu_title" groupName={LabelGroup.ORGANIZER_MENU} />
                      </NavigationMenuLink>
                    </Link>
                  </NavigationMenuItem>
                )}
                {/* {organizations?.length > 0 && (
                  <NavigationMenuItem>
                    <Link to="/organizations">
                      <NavigationMenuLink
                        active={window.location.pathname === '/organizations'}
                        className={cn(
                          navigationMenuTriggerStyle(),
                          'bg-gray rounded-none text-white hover:bg-black/70 hover:text-whited focus:bg-black/70 focus:text-white  data-[active]:bg-black/70 data-[state=open]:bg-black/70'
                        )}
                      >
                        <HouseCheck size={16} className="mr-2" />
                        <Label name="organizations_menu_title" groupName={LabelGroup.ORGANIZER_MENU} />
                      </NavigationMenuLink>
                    </Link>
                  </NavigationMenuItem>
                )} */}
                {payments?.length > 0 && (
                  <NavigationMenuItem>
                    <Link to="/payments">
                      <NavigationMenuLink
                        active={window.location.pathname === '/payments'}
                        className={cn(navigationMenuTriggerStyle(), 'bg-black/30 rounded-none h-[60px]   px-8  text-white hover:bg-black/70 hover:text-whited focus:bg-black/70 focus:text-white  data-[active]:bg-black/70 data-[state=open]:bg-black/80')}>
                        <Receipt size={20} className="mr-2" />
                        <Label name="payments_menu_title" groupName={LabelGroup.ORGANIZER_MENU} />
                      </NavigationMenuLink>
                    </Link>
                  </NavigationMenuItem>
                )}
              </NavigationMenuList>
            </NavigationMenu>
          </div>
          <div className="flex items-center gap-x-2">
            <div className="hidden lg:block">
              <OrganizationDropdown />
            </div>

            <UserProfileDropdown />
          </div>
        </div>
      </div>
    </header>
  );
}
