import { convertUTCtoLocal } from '@/utils/convertUtcToLocal';
import { EventComment } from '../types';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { AlertTriangle } from 'lucide-react';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { cn } from '@/utils';

interface CommentProps {
  comment?: EventComment | null;
  IconComponent?: React.ReactNode | JSX.Element | any;
  className?: string;
  label?: string;
}

export function Comment({ comment, label = 'action_required', IconComponent = <AlertTriangle className="w-4 h-4 mt-1" />, className }: CommentProps) {
  if (!comment) return null;

  return (
    <Alert className={cn('text-amber-900 bg-amber-50 border rounded-none border-amber-300  [&>svg]:text-amber-500', className)}>
      {IconComponent}
      <AlertTitle>
        <Label name={label} groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
      </AlertTitle>
      <AlertDescription className="flex flex-col gap-1">
        {comment?.logText?.text}
        <span className='italic'>{comment?.inserted && convertUTCtoLocal(comment.inserted).toLocaleString()}</span>
      </AlertDescription>
    </Alert>
  );
}
