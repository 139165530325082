import { useOrganizerStore } from '@/stores/organizerStore';
import { usePayments } from '../api/get-payments';
import { Card } from '@/components/ui/card';
import { Link } from 'react-router-dom';
import { convertUTCtoLocal } from '@/utils/convertUtcToLocal';
import { PaymentIcon } from '../components/payment-icon';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { Button } from '@/components/ui/button';
import { useEvents } from '@/features/event/api/getEvents';
import { ProductType } from '../types';
import { Skeleton } from '@/components/ui/skeleton';

export function Payments() {
  const organizerId = useOrganizerStore((state) => state.organizationId);
  const { data = [] } = usePayments({ organizerId, config: { enabled: !!organizerId } });
  const { data: events = [], isLoading } = useEvents({
    organizerId,
    config: {
      enabled: !!organizerId,
    },
  });

  return (
    <div className="space-y-6 pl-2 text-base">
      <h2 className="text-xl font-semibold">
        <Label name="payments_menu_title" groupName={LabelGroup.ORGANIZER_PAYMENT_LIST} />
      </h2>
      <div className="space-y-4 md:space-y-2">
        {data
          .filter(
            (payment) =>
              payment?.productType !== ProductType.ProgramOneTime && payment?.productType !== ProductType.ProgramYearly
          )
          .sort((a, b) => +new Date(b.payment.payDate) - +new Date(a.payment.payDate))
          .map(({ name, payment }) => (
            <Card key={payment.paymentId} className="flex px-4 py-3 items-center justify-between">
              <div className="flex gap-2 items-start flex-col">
                <div className="w-full   md:max-w-full">
                  <Link
                    to={`/events/edit/${name.substring(0, name.indexOf('-'))}`}
                    className="underline flex items-center font-semibold font-base"
                  >
                    {name.substring(0, name.indexOf('-'))} -{' '}
                    {isLoading ? (
                      <Skeleton className="ml-2 w-48 h-4" />
                    ) : (
                      events.find((event) => event.eventId.toString() === name.substring(0, name.indexOf('-') - 1))
                        ?.title
                    )}
                  </Link>
                </div>
                <div className="font-semibold text-md flex justify-between items-center gap-x-2">
                  <div className="flex items-center">
                    <PaymentIcon payType={payment.payType} />
                  </div>
                  <div>{payment.amount} SEK</div>
                </div>
                <div className="w-40 text-sm">{convertUTCtoLocal(payment?.payDate?.toString())}</div>

                <div className="underline text-sm w-24">
                  <Link to={`/payment/confirmation/${payment.paymentId}`}>
                    <Button variant="outline" size="sm">
                      <Label name="show_payment" groupName={LabelGroup.ORGANIZER_PAYMENT_LIST} />
                    </Button>
                  </Link>
                </div>
              </div>
            </Card>
          ))}
      </div>
    </div>
  );
}
