import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { UserType } from '@/features/user';
import { LabelGroup } from '@/features/label/types';
import { Label } from '@/features/label/components/label';
import { useFormContext } from 'react-hook-form';
import { useMe } from '@/features/auth/api/get-me';

export function DigitalEventSection({ defaultCollapsed = false }: { defaultCollapsed?: boolean }) {
  const { data: user } = useMe({});
  const userType = user?.role;

  const form = useFormContext();

  const labelGroup = userType === UserType.ADMIN || userType === UserType.MANAGER ? LabelGroup.MANAGER_EVENT_HANDLING : LabelGroup.ORGANIZER_EVENT_FORM;

  return (
    <Card>
      <Accordion type="single" collapsible defaultValue={defaultCollapsed ? '' : 'interactive_meeting'}>
        <AccordionItem className="[&[data-state=open]>.card-header]:bg-gray-medium [&[data-state=open]>.card-header]:text-white [&[data-state=open]>.card-header]:rounded-t-md" value="interactive_meeting">
          <CardHeader className="py-3">
            <AccordionTrigger className="py-0 text-md">
              <CardTitle>
                <Label name="interactive_meeting_title" groupName={labelGroup} />
              </CardTitle>
            </AccordionTrigger>
          </CardHeader>
          <AccordionContent>
            <CardContent className="pt-6 text-sm space-y-6">
              <CardDescription>
                <Label name="interactive_meeting_description" groupName={labelGroup} />
              </CardDescription>
              <FormField
                control={form.control}
                name="digitalMeetingUrl"
                render={({ field }) => (
                  <FormItem className=" space-y-2">
                    <FormLabel className="font-semibold">
                      <Label name="digital_meeting_url" groupName={labelGroup} />
                    </FormLabel>
                    <FormDescription className="text-muted-foreground">
                      <Label name="digital_meeting_url_description" groupName={labelGroup} />
                    </FormDescription>
                    <FormControl>
                      <Input {...field} className="w-full max-w-full" maxLength={100} />
                    </FormControl>
                    <FormDescription className="italic">
                      <Label name="you_have_letters_left" groupName={labelGroup} /> {100 - (field?.value?.length ?? 0)} <Label name="letters_left" groupName={labelGroup} />
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="digitalMeetingDescription"
                render={({ field }) => (
                  <FormItem className="w-full space-y-2">
                    <FormLabel className="font-semibold">
                      <Label name="digital_meeting_description" groupName={labelGroup} />
                    </FormLabel>
                    <FormDescription className="text-muted-foreground">
                      <Label name="digital_meeting_description_description" groupName={labelGroup} />
                    </FormDescription>
                    <FormControl>
                      <Textarea {...field} className="w-full" rows={1} maxLength={150} />
                    </FormControl>
                    <FormDescription className="italic">
                      <Label name="you_have_letters_left" groupName={labelGroup} /> {150 - (field?.value?.length ?? 0)} <Label name="letters_left" groupName={labelGroup} />
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </CardContent>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </Card>
  );
}
