import { Button } from '@/components/ui/button';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { CreditCard, File } from 'lucide-react';
import { Link } from 'react-router-dom';
import { PaymentPreConfirmation } from '../components/payment-pre-confirmation';
import { useOrganizerStore } from '@/stores/organizerStore';
import { useOrderRows } from '../api/get-order-rows';
import { NothingToPay } from '../components/nothing-to-pay';

export function Payment() {
  const organizerId = useOrganizerStore((state) => state.organizationId);

  const { data: orderRows, isLoading } = useOrderRows({
    organizerId: organizerId,
    config: {
      enabled: !!organizerId,
    },
  });

  if (isLoading) return <div>Loading...</div>;
  if (orderRows?.length === 0) return <NothingToPay />;

  return (
    <div className="space-y-8">
      <div>
        <h2 className="font-semibold text-lg">
          <Label name="payment-pre-confirmation" groupName={LabelGroup.ORGANIZER_PAYMENT} />
        </h2>
      </div>
      <PaymentPreConfirmation />
      <div>
        <h2 className="font-semibold text-lg">
          <Label name="choose_payment_method" groupName={LabelGroup.ORGANIZER_PAYMENT} />
        </h2>
      </div>

      <div className="flex gap-2">
        <Link to="/payment/card">
          <Button className="flex flex-col w-fit h-full gap-2 p-4 !rounded-none" variant="outline">
            <div className="flex items-center">
              <CreditCard className="h-6 w-6  text-muted-foreground" />
              {/* <SwishIcon className="h-6 w-16 text-muted-foreground" /> */}
            </div>
            <Label name="card_payment_and_swish_button_text" groupName={LabelGroup.ORGANIZER_PAYMENT} />{' '}
          </Button>
        </Link>
        <Link to="/payment/invoice">
          <Button className="flex flex-col w-36 h-full gap-2 p-4 !rounded-none" variant="outline">
            <File className="h-6  w-6 text-muted-foreground" />
            <span>
              <Label name="invoice_payment_button_text" groupName={LabelGroup.ORGANIZER_PAYMENT} />
            </span>
          </Button>
        </Link>
      </div>
    </div>
  );
}
