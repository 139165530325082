import React, { createContext, useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';

// Create a context for route history
const RouteHistoryContext = createContext<any>(null);

// Custom hook to use route history context easily
export const useRouteHistory = () => {
  return useContext(RouteHistoryContext);
};

// Provider component to wrap around the app
export const RouteHistoryProvider = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation(); // Hook to get the current location (path)
  const [history, setHistory] = useState<string[]>([]); // State to store the history of routes

  // Whenever the location changes, update the history
  useEffect(() => {
    setHistory((prevHistory) => [...prevHistory, location.pathname]);
  }, [location]);

  return <RouteHistoryContext.Provider value={history}>{children}</RouteHistoryContext.Provider>;
};
