import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useToast } from '@/components/ui/use-toast';
import { label } from '@/utils/label';
import { LabelGroup } from '@/features/label/types';
import { TEvent } from '@/features/event/types';
// import { updateEvent } from '@/features/event/api/updateEvent';

const QUERY_KEY = 'events';
const API_PATH = '/manager/savereview/';

type UpdateDTO = {
  titleComment: string;
  descriptionComment: string;
  socialIssueComment: string;
  generalComment: string;
};

export const updateReview = async (data: TEvent & UpdateDTO): Promise<void> => {
  const formattedData = {
    ...data,
    languages: Array.isArray(data.languages) ? data.languages.join(';') : data.languages,
    times: data.times.map(time => {
      // Check if eventStart and eventEnd are already in ISO format
      const isEventStartISO = time.eventStart?.includes('T');
      const isEventEndISO = time?.eventEnd?.includes('T') 

      // If both are in ISO format, return the original object
      if (isEventStartISO && isEventEndISO) {
        return {
          ...time,
          inserted: new Date().toISOString() // Only update the inserted date
        };
      }

      // If not, adjust the date to account for timezone offset and convert to ISO strings
      const eventStart = new Date(time.eventStart);
      const eventEnd = new Date(time.eventEnd);

      // Convert to ISO strings directly without adjusting offsets
      const eventStartISO = eventStart.toISOString();
      const eventEndISO = eventEnd.toISOString();

      // Return a new object with the original time data plus the converted ISO strings
      return {
        ...time,
        inserted: new Date().toISOString(), // Update inserted date
        eventStart: eventStartISO,
        eventEnd: eventEndISO
      };
    })
  };

  try {
    // await updateEvent(data);
    return await axios.patch(
      `${API_PATH}${data.eventId}`,
      formattedData
      // {
      //   params: {
      //     titleComment: data.titleComment,
      //     descriptionComment: data.descriptionComment,
      //     socialIssueComment: data.socialIssueComment,
      //     generalComment: data.generalComment,
      //     status: data.status,
      //     returnDue: data.returnDue,
      //   },
      // }
    );
  } catch (error) {
    // Handle error if needed
    console.error('Error updating review:', error);
    throw error; // Re-throw the error if you want to propagate it
  }
};

type UseUpdateReviewOptions = {
  eventId: number;
  config?: MutationConfig<typeof updateReview>;
};

export const useUpdateReview = ({ config, eventId }: UseUpdateReviewOptions) => {
  const { toast } = useToast();

  return useMutation({
    onSuccess: async (data: any) => {
      if (eventId !== data?.eventId) {
        await queryClient.cancelQueries([QUERY_KEY, eventId]);
        queryClient.removeQueries([QUERY_KEY, eventId]);
      }

      queryClient.invalidateQueries([QUERY_KEY]);
      queryClient.refetchQueries([QUERY_KEY]);

      queryClient.refetchQueries([QUERY_KEY, data?.eventId]);
      queryClient.invalidateQueries([QUERY_KEY, data?.eventId]);

      toast({ variant: 'success', title: label('event_review_updated', LabelGroup.MANAGER_EVENT_HANDLING) });

      return data;
    },
    ...config,
    mutationFn: updateReview
  });
};
