import { EventComment, EventLog, LogCategory, LogComment } from '@/features/event/types';
import { convertPascalToCamel } from './convert-pascal-to-camel-case';

export function parseEventLogToComments(eventLog: EventLog[]): EventComment[] {
  return (
    eventLog
      ?.filter((log) => log.category === LogCategory.COMMENT)
      ?.sort((a, b) => +new Date(b.inserted) - +new Date(a.inserted))
      ?.map((log) => ({
        ...log,
        logText: convertPascalToCamel<LogComment>(JSON.parse(log.logText ?? '{}')),
      })) ?? []
  );
}
