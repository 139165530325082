import * as React from 'react';
// import { ErrorBoundary } from "react-error-boundary";

import { QueryClientProvider } from 'react-query';
import { queryClient } from '@/lib/react-query';
import { ThemeProvider } from './theme';
import { TooltipProvider } from '@/components/ui/tooltip';
import { LabelProvier } from './label';
import { Button } from '@/components/ui/button';
import { ErrorBoundary } from '@sentry/react';

const ErrorFallback = (_: any) => {
  return (
    <div className="w-full h-screen flex items-center ">
      <div className="max-w-4xl m-auto flex flex-col justify-center items-center space-y-6" role="alert">
        {_?.error?.response?.status && (
          <h2 className="text-2xl font-bold font-header"> Status {_?.error?.response?.status}</h2>
        )}
        <h2 className="text-lg font-bold font-header">Ooops, något gick fel...</h2>
        <Button size="sm" className="mt-4" onClick={() => window.location.assign(window.location.origin)}>
          Ladda om sidan
        </Button>
      </div>
    </div>
  );
};

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <ErrorBoundary
      fallback={ErrorFallback}
      showDialog={true}
      dialogOptions={{
        title: 'Ooops, något gick fel',
        subtitle: 'Försök att ladda om sidan',
        buttonText: 'Ladda om sidan',
        onButtonClick: () => window.location.assign(window.location.origin),
      }}
    >
      <TooltipProvider>
        <ThemeProvider>
          <QueryClientProvider client={queryClient} contextSharing>
            <LabelProvier>{children}</LabelProvier>
          </QueryClientProvider>
        </ThemeProvider>
      </TooltipProvider>
    </ErrorBoundary>
  );
};
