import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';

const QUERY_KEY = 'events';
const API_PATH = '/event/session/';

export const startSession = async (eventId: number): Promise<void> => {
  return axios.patch(`${API_PATH}${eventId}`, {});
};

type UseReviewOptions = {
  eventId: number;
  config?: MutationConfig<typeof startSession>;
};

export const useStartSession = ({ config, eventId }: UseReviewOptions) => {
  return useMutation({
    onMutate: () => {
      queryClient.invalidateQueries([QUERY_KEY, eventId]);
      queryClient.refetchQueries([QUERY_KEY, eventId]);
    },
    onError: _ => {
      console.error('error');
      // toast({ variant: 'error', title: error.message });
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEY, eventId]);
      queryClient.refetchQueries([QUERY_KEY, eventId]);

      // toast({ variant: 'success', title: label('you_review_event', LabelGroup.MANAGER_EVENT_HANDLING) });
    },
    ...config,
    mutationFn: () => startSession(eventId)
  });
};
