import { Suspense } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Menu } from './Menu';
import { useIsOverflow } from '@/hooks/use-is-overflow';
import { cn } from '@/utils';
import { RouteHistoryProvider } from '@/providers/route-history';

const WIDE_ROUTES = ['events/edit', 'events/create'];

export function Layout() {
  const [_, ref] = useIsOverflow();

  const { pathname } = useLocation();

  return (
    <RouteHistoryProvider>
      <div className={cn('min-h-screen flex flex-col overflow-x-hidden organizer')}>
        <Menu />
        <main
          ref={ref}
          className={cn('flex-1  max-h-[calc(100lvh_-_52px)] md:max-h-[calc(100lvh_-_121px)] overflow-y-auto')}
        >
          <div
            className={cn(
              WIDE_ROUTES.some((route) => pathname.includes(route))
                ? 'w-full'
                : 'lg:p-4 p-6 w-full max-w-[1440px]  mx-auto'
            )}
          >
            <Suspense fallback={<div />}>
              <Outlet />
            </Suspense>
          </div>
        </main>
      </div>
    </RouteHistoryProvider>
  );
}
