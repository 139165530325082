import { AlertTriangle } from 'lucide-react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';

import { LabelGroup } from '@/features/label/types';
import React from 'react';
import { Label } from '@/features/label/components/label';
import { label } from '@/utils/label';
import { Input } from '@/components/ui/input';
import { FormControl, FormItem, FormLabel } from '@/components/ui/form';

interface DeleteButtonProps {
  eventId: number;
  onDelete: () => void;
  ButtonComponent: React.ReactNode;
  TitleComponent: React.ReactNode | string;
  DescriptionComponent: React.ReactNode | string;
  DeleteButtonTextComponent?: React.ReactNode | string;
}

export function DeleteEventButton({
  eventId,
  onDelete,
  ButtonComponent,
  TitleComponent,
  DescriptionComponent,
  DeleteButtonTextComponent,
}: DeleteButtonProps) {
  const [isConfirmed, setIsConfirmed] = React.useState(false);

  return (
    <AlertDialog>
      <AlertDialogTrigger className="w-fit text-destructive">{ButtonComponent}</AlertDialogTrigger>
      <AlertDialogContent className="space-y-4">
        <AlertDialogHeader>
          <AlertDialogTitle className="flex items-center">
            <AlertTriangle className="w-6 h-6 mr-3" />
            {TitleComponent}
          </AlertDialogTitle>
          <AlertDialogDescription className="space-y-4">
            {DescriptionComponent}
            <div className="space-y-4">
              <FormItem className="rounded-md p-4 px-4 border">
                <FormLabel>
                  <Label name="type_event_id_for_delete" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />{' '}
                </FormLabel>
                <FormControl className="w-full">
                  <Input type="text" onChange={(e) => setIsConfirmed(e.target.value === eventId.toString())} />
                </FormControl>
              </FormItem>
            </div>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>
            <Label name="cancel" groupName={LabelGroup.GLOBAL} />
          </AlertDialogCancel>
          <AlertDialogAction color="destructive" className="bg-destructive" disabled={!isConfirmed} onClick={onDelete}>
            {DeleteButtonTextComponent ?? label('confirm_deletion_button_text', LabelGroup.GLOBAL)}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
