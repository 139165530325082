import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';
import { Spinner } from '@/components/ui/spinner';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { useDashboardSummary } from '@/features/statistic/api/get-dashboard-summary';
import { ExportByFilterDialog } from './export-by-filters-dialog';
import { Separator } from '@/components/ui/separator';

export function Summary() {
  const { data: summary = {}, isLoading, refetch, dataUpdatedAt, isRefetching } = useDashboardSummary({});

  if (isLoading) return <Skeleton className="w-[500px] h-96" />;

  return (
    <Card className=" w-[500px] h-fit">
      <CardHeader>
        <CardTitle className="flex justify-between">
          <Label name="overview_title" groupName={LabelGroup.MANAGER_DASHBOARD} />
        </CardTitle>
      </CardHeader>
      <CardContent className="divide-y divide-input ">
        {Object.entries(summary).map(([key, value]: [string, any]) => (
          <div key={key} className="flex  items-center text-sm justify-between py-2 h-">
            <Label name={key} groupName={LabelGroup.MANAGER_DASHBOARD} />
            <div className="text-sm font-bold">{value.toString()}</div>
          </div>
        ))}
      </CardContent>

      <CardFooter className="flex flex-col items-start gap-2 ">
        <Separator className="my-2" />
        <div className="flex items-center gap-x-2 w-full">
          <Button onClick={() => refetch()}>
            {isRefetching && <Spinner className="w-4 h-4 mr-2 text-primary-foreground" />}
            <Label name="update" groupName={LabelGroup.GLOBAL} />
          </Button>
          <ExportByFilterDialog />
        </div>
        <CardDescription>
          <span className="text-sm italic text-muted-foreground">
            <Label name="update_at" groupName={LabelGroup.MANAGER_DASHBOARD} />{' '}
            {new Date(dataUpdatedAt).toLocaleString('sv-SE', {
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
            })}
          </span>
        </CardDescription>
      </CardFooter>
    </Card>
  );
}
