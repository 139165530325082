import { DataTableSkeleton } from '@/components/data-table-skeleton';
import { DataTable } from '@/components/ui/data-table';
import { useArchivedEvents } from '@/features/admin/api/get-archived-events';
import { columns } from '@/features/admin/components/event-columns';
import { EventFilters } from '@/features/admin/components/(filters)/event-filters';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { useYears } from '@/features/settings/api/get-years';
import { useState } from 'react';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';

export function ArchivedEventsRoute() {
  const [filterYear, setFilterYear] = useState(new Date().getFullYear());

  const { data: years = [], isFetched } = useYears({});
  const { data: archivedEvents = [], isLoading } = useArchivedEvents({
    year: filterYear,
    config: { enabled: !!isFetched },
  });

  return (
    <div className="space-y-6 flex flex-col h-full">
      <div className="flex w-full items-center justify-between">
        <div>
          <div className="flex gap-x-4 items-center">
            <h3 className="text-xl font-semibold">
              <Label name="arhived_events" groupName={LabelGroup.MANAGER_EVENT_LIST} />
            </h3>
            <DropdownMenu>
              <DropdownMenuTrigger>
                <Button variant="outline">År {filterYear}</Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuLabel>
                  <Label name="filter_by_year" groupName={LabelGroup.MANAGER_EVENT_LIST} />
                </DropdownMenuLabel>
                <DropdownMenuSeparator />
                {years.map((year) => (
                  <DropdownMenuCheckboxItem
                    checked={year.yearId.toString() === filterYear.toString()}
                    key={year.yearId}
                    onClick={() => setFilterYear(year.yearId)}
                  >
                    {year.yearId}
                  </DropdownMenuCheckboxItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </div>
      <div className="space-y-2">
        {!isLoading ? (
          <DataTable
            columns={columns}
            data={archivedEvents}
            columnVisibility={{
              category: false,
              organizerContactEmail: false,
            }}
            FacetedFilters={EventFilters}
          />
        ) : (
          <DataTableSkeleton />
        )}
      </div>
    </div>
  );
}
