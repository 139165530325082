import { APPROVED_FOOD_OPTIONS, PRODUCTS_AND_MARKETING_OPTIONS, SERVICE_OPTIONS, WASTE_AND_RECYCLING_OPTIONS } from '../data/environment-diploma-constants';
import { TEvent } from '../types';

export function checkEnvironmentDiplomaQualification(event: TEvent) {
  const isProductsAndMarketingApproved = PRODUCTS_AND_MARKETING_OPTIONS.filter(option => event[option as keyof TEvent])?.length >= 2;
  const isWasteAndRecyclingApproved = WASTE_AND_RECYCLING_OPTIONS.filter(option => event[option as keyof TEvent])?.length >= 2;
  const isServiceApproved = SERVICE_OPTIONS.filter(option => event[option as keyof TEvent])?.length >= 2;
  const isFoodApproved = APPROVED_FOOD_OPTIONS.filter(option => event[option as keyof TEvent])?.length >= 2;

  return {
    isQualified: isProductsAndMarketingApproved && isWasteAndRecyclingApproved && isServiceApproved && isFoodApproved,
    isProductsAndMarketingApproved,
    isWasteAndRecyclingApproved,
    isServiceApproved,
    isFoodApproved
  };
}
