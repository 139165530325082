import { AlertTriangle } from 'lucide-react';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { Alert, AlertTitle } from '@/components/ui/alert';

export function YearDueAlert() {
  return (
    <Alert className="bg-amber-50 text-amber-900  border border-amber-300 rounded-none">
      <AlertTriangle className="h-4 w-4 mt-1 stroke-amber-600" />
      <AlertTitle>
        <Label name="year_due" groupName={LabelGroup.ORGANIZER_EVENT_FORM} />
      </AlertTitle>
    </Alert>
  );
}
