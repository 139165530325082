import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';

const QUERY_KEY = 'events';
const API_PATH = '/event/end-session/';

export const endSession = (eventId?: number): Promise<void> => {
  return axios.patch(`${API_PATH}${eventId}`, {});
};

type UseEndSesionOptions = {
  eventId: number;
  config?: MutationConfig<typeof endSession>;
};

export const useEndSession = ({ config, eventId }: UseEndSesionOptions) => {
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEY, eventId]);
      queryClient.refetchQueries([QUERY_KEY, eventId]);
    },
    ...config,
    mutationFn: () => endSession(eventId),
  });
};
