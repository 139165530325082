import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { useSettings } from '../api/getSettings';
import { Setting } from '../types';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { Calendar as CalendarIcon } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { cn } from '@/utils';
import { Input } from '@/components/ui/input';
import { useUpdateSetting } from '../api/update-setting';
import { label } from '@/utils/label';
import { useToast } from '@/components/ui/use-toast';

const schema = z.object({
  settings: z.array(
    z.object({
      settingId: z.number(),
      name: z.string(),
      value: z.string(),
      sortOrder: z.number(),
      data: z.object({
        amount: z.number(),
        start: z.string(),
      }),
    })
  ),
});

export function PricePeriodSettings() {
  const { data: settings } = useSettings({});

  const pricePeriodSettings = Object.values(settings?.priceperiod || []).map((value: Setting) => ({
    ...value,
    data: typeof value.data === 'string' ? JSON.parse(value.data) : value.data, // Ensure data conforms to the expected structure
  }));

  const settingMutation = useUpdateSetting({});
  const { toast } = useToast();

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    values: {
      settings: pricePeriodSettings ?? [],
    },
  });

  const handleSubmit = async (values: z.infer<typeof schema>) => {
    try {
      const data = values.settings.map((setting) => ({
        ...setting,
        data: JSON.stringify(setting.data) as string,
      }));

      const promises = data.map((setting) => settingMutation.mutateAsync(setting));
      const results = await Promise.allSettled(promises);
      const allIsFulfilled = results.every((result) => result.status === 'fulfilled');

      const responseTitle = allIsFulfilled ? 'settings_updated_successfully' : 'error_updating_settings';

      toast({
        variant: allIsFulfilled ? 'success' : 'destructive',
        title: label(responseTitle, LabelGroup.ADMIN_SETTINGS),
      });
    } catch (error) {
      console.error('Error updating settings', error);
    }
  };

  if (!settings?.priceperiod) return null; // Handle missing data gracefully

  return (
    <Form {...form}>
      <form
        onKeyDown={(event) => event.key === 'Enter' && event.preventDefault()}
        onSubmit={form.handleSubmit(handleSubmit)}
        className="space-y-8"
      >
        <Card className="w-[600px]">
          <CardHeader>
            <CardTitle>
              <Label name="PricePeriodSettingsCardTitle" groupName={LabelGroup.ADMIN_SETTINGS} />
            </CardTitle>
            <CardDescription>
              <Label name="PricePeriodSettingsCardDescription" groupName={LabelGroup.ADMIN_SETTINGS} />
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-2">
            <div className="grid grid-cols-2 text-sm">
              <Label name="Start" groupName={LabelGroup.ADMIN_SETTINGS} />
              <Label name="Amount" groupName={LabelGroup.ADMIN_SETTINGS} />
            </div>
            <div className="space-y-4">
              {form.watch('settings').map((setting, index) => (
                <div key={setting.settingId} className="grid grid-cols-2">
                  <div>
                    <FormField
                      control={form.control}
                      name={`settings.${index}.data.start`}
                      render={({ field }) => (
                        <FormItem>
                          <FormControl className="">
                            <Popover>
                              <PopoverTrigger asChild>
                                <Button
                                  variant={'outline'}
                                  className={cn(
                                    'rounded-none w-64 justify-start',
                                    !field.value && 'text-muted-foreground '
                                  )}
                                >
                                  <CalendarIcon className="mr-2 h-4 w-4" />
                                  {field?.value
                                    ? new Date(field.value).toLocaleDateString('sv-SE', {
                                        day: 'numeric',
                                        month: 'numeric',
                                        year: 'numeric',
                                      })
                                    : 'Select date'}
                                </Button>
                              </PopoverTrigger>

                              <PopoverContent className="w-auto p-0">
                                <Calendar
                                  mode="single"
                                  selected={new Date(field.value)}
                                  onSelect={(date) =>
                                    field.onChange(
                                      date &&
                                        String(
                                          date.toLocaleDateString('sv-SE', {
                                            day: 'numeric',
                                            month: 'numeric',
                                            year: 'numeric',
                                          })
                                        )
                                    )
                                  }
                                  disabled={(date) => date < new Date()} // Disable past dates
                                  initialFocus
                                />
                              </PopoverContent>
                            </Popover>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>

                  <FormField
                    control={form.control}
                    name={`settings.${index}.data.amount`}
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Input
                            type="number"
                            className="w-24"
                            {...field}
                            onChange={(e) => field.onChange(Number(e.target.value))}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              ))}
            </div>
          </CardContent>
          <CardFooter>
            <Button disabled={!form.formState.isDirty} type="submit">
              <Label name="Save" groupName={LabelGroup.GLOBAL} />
            </Button>
          </CardFooter>
        </Card>
      </form>
    </Form>
  );
}
