import { useSettings } from '@/features/settings/api/getSettings';
import { AlertCircle, CheckCircle, Info, XCircle } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import DOMPurify from 'dompurify';
import { parse } from 'marked';

type TBanner = {
  hidden: boolean;
  message: string;
  title: string;
  icon: 'info' | 'warning' | 'error' | 'success' | null;
  severity: 'info' | 'warning' | 'error' | 'success' | null;
};

const icon = {
  info: <Info className="w-6 h-6 text-[white_!important]" />,
  warning: <AlertCircle className="w-6 h-6 text-[white_!important]" />,
  error: <XCircle className="w-6 h-6 text-[white_!important]" />,
  success: <CheckCircle className="w-6 h-6 text-[white_!important]" />,
};

export function Banner() {
  const { data: settings = {} } = useSettings({});

  const bannerSetting = settings?.banner?.[0] ?? null;

  const banner: TBanner | null = bannerSetting ? JSON.parse((bannerSetting.data as string) ?? '{}') : null;

  if (banner?.hidden || !banner) {
    return null;
  }

  return (
    <Alert variant={banner.severity} className='rounded-none '>
      {banner.icon ? icon[banner.icon] : null}
      <AlertTitle className='ml-2'>{banner?.title}</AlertTitle>
      <AlertDescription  className='ml-2'>
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(parse(banner?.message ?? '') as string) }} />
      </AlertDescription>
    </Alert>
  );
}
