import { ConfirmButton } from '@/components/confirm-button';
import { Button } from '@/components/ui/button';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { useSettings } from '@/features/settings/api/getSettings';
import { useCancelEvent } from '../api/cancel-event';
import { useDeleteEvent } from '../api/deleteEvent';
import { Spinner } from '@/components/ui/spinner';
import { EventStatus, TEvent } from '../types';
import { useNavigate } from 'react-router-dom';
// import { useUnpublishEvent } from '../api/unpublish-event';
import { useActivateEvent } from '../api/activate-event';
import { DeleteEventButton } from './delete-event-button';
import { CancelEventButton } from './cancel-event-button';

interface DeviationSettingsProps {
  event: TEvent;
}

export default function DeviationSettings({ event }: DeviationSettingsProps) {
  const { mutateAsync: deleteEvent, isLoading: isDeleting } = useDeleteEvent({ eventId: event?.eventId });
  const { mutateAsync: cancelEvent, isLoading: isCancelling } = useCancelEvent({});
  // const { mutateAsync: unpublishEvent, isLoading: isUnPublishing } = useUnpublishEvent({});
  const { mutateAsync: activateEvent, isLoading: isActivating } = useActivateEvent({});

  const navigate = useNavigate();

  const { data: settings } = useSettings({});

  const lastDeleteDate = settings?.deadline?.find((deadline) => deadline.value === 'LastDeleteDate')?.data;
  const isDeleteEnabled = lastDeleteDate
    ? new Date(lastDeleteDate?.toString()).getFullYear() > new Date().getFullYear() ||
      (new Date(lastDeleteDate?.toString()).getFullYear() === new Date().getFullYear() &&
        (new Date(lastDeleteDate?.toString()).getMonth() > new Date().getMonth() ||
          (new Date(lastDeleteDate?.toString()).getMonth() === new Date().getMonth() &&
            new Date(lastDeleteDate?.toString()).getDate() >= new Date().getDate())) &&
        event?.status !== EventStatus.Pending)
    : false;

  const isCancelEnabled = event?.status === EventStatus.Approved && !isDeleteEnabled;
  // const isUnPublishEnabled =
  //   (event?.status === EventStatus.Approved || event?.status === EventStatus.Cancelled) && !isDeleteEnabled;
  const isActivatiable = event?.status === EventStatus.Cancelled;

  const handleDelete = async () => {
    try {
      await deleteEvent({ eventId: event.eventId });
      navigate('/events');
    } catch (error) {
      console.error('error', error);
    }
  };

  return (
    <div className="pb-4 space-x-2 ">
      {isDeleteEnabled && (
        <DeleteEventButton
          eventId={event.eventId}
          onDelete={handleDelete}
          TitleComponent={<Label name="delete_event_title" groupName={LabelGroup.ORGANIZER_EVENT_FORM} />}
          DescriptionComponent={<Label name="delete_event_description" groupName={LabelGroup.ORGANIZER_EVENT_FORM} />}
          ButtonComponent={
            <Button type="button" variant="destructive">
              {isDeleting && <Spinner className="mr-2 text-white h-4 w-4" />}
              <Label name="delete_event_button" groupName={LabelGroup.ORGANIZER_EVENT_FORM} />
            </Button>
          }
        />
      )}
      {isCancelEnabled && (
        <CancelEventButton
          eventId={event.eventId}
          onConfirm={() => cancelEvent({ eventId: event.eventId })}
          TitleComponent={<Label name="cancel_event_title" groupName={LabelGroup.ORGANIZER_EVENT_FORM} />}
          DescriptionComponent={<Label name="cancel_event_description" groupName={LabelGroup.ORGANIZER_EVENT_FORM} />}
          ButtonComponent={
            <Button type="button" variant="destructive">
              {isCancelling && <Spinner className="mr-2 text-primary h-4 w-4" />}
              <Label name="cancel_event_button" groupName={LabelGroup.ORGANIZER_EVENT_FORM} />
            </Button>
          }
        />
      )}
      {isActivatiable && (
        <ConfirmButton
          onConfirm={() => activateEvent({ eventId: event.eventId })}
          TitleComponent={<Label name="activate_event_title" groupName={LabelGroup.ORGANIZER_EVENT_FORM} />}
          DescriptionComponent={<Label name="activate_event_description" groupName={LabelGroup.ORGANIZER_EVENT_FORM} />}
          ButtonComponent={
            <Button type="button" variant="outline" className="text-primary">
              {isActivating && <Spinner className="mr-2 text-primary h-4 w-4" />}
              <Label name="activate_event_button" groupName={LabelGroup.ORGANIZER_EVENT_FORM} />
            </Button>
          }
        />
      )}
      {/* {isUnPublishEnabled && (
        <ConfirmButton
          onConfirm={() => unpublishEvent({ eventId: event.eventId })}
          TitleComponent={<Label name="unpublish_event_title" groupName={LabelGroup.ORGANIZER_EVENT_FORM} />}
          DescriptionComponent={
            <Label name="unpublish_event_description" groupName={LabelGroup.ORGANIZER_EVENT_FORM} />
          }
          ButtonComponent={
            <Button type="button" variant="outline" className="text-primary">
              {isUnPublishing && <Spinner className="mr-2 text-primary h-4 w-4" />}
              <Label name="unpublish_event_button" groupName={LabelGroup.ORGANIZER_EVENT_FORM} />
            </Button>
          }
        />
      )} */}
    </div>
  );
}
