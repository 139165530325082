import { axios } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';
import storage from '@/utils/storage';

async function logout() {
  await axios.post('/logout', {
    refreshToken: storage.getRefreshToken(),
  });
  storage.clearAccessToken();
  storage.clearRefreshToken();
  queryClient.invalidateQueries('me');

}

export { logout };
