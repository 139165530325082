import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { TimelineConnector, TimelineHeader, TimelineItem, TimelineTime, TimelineTitle } from '@/components/ui/timeline';
import { EventLog, LogCategory } from '@/features/event/types';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { UserType } from '@/features/user';
import { cn } from '@/utils';
import { convertPascalToCamel } from '@/utils/convert-pascal-to-camel-case';
import { convertUTCtoLocal } from '@/utils/convertUtcToLocal';
import { formateTime } from '@/utils/formatTime';
import { ClockIcon, Pen, PenOff, UnlockIcon } from 'lucide-react';
import { useManagers } from '../../api/getManagers';

export function ReviewTimeline({ log: { user, logText, inserted, category } }: { log: EventLog }) {
  const data = convertPascalToCamel<number | any>(JSON.parse(logText ?? '{}'));
  const createdAtTime = formateTime(convertUTCtoLocal(inserted));

  const { data: managers } = useManagers({});

  const editor = managers?.find((manager) => manager.managerId == (data?.editor ?? data));

  const by = typeof data === 'number' ? data : data?.editor ?? user?.userId;

  return (
    <TimelineItem>
      <TimelineConnector className="bg-gray-medium/20" />
      <TimelineHeader>
        {by !== user?.userId && category === LogCategory.REVIEW_END ? (
          <UnlockIcon
            className={'-translate-x-2 flex items-center rounded-md gap-x-2 bg-blue-700 w-8 h-8 p-2 text-white'}
          />
        ) : category === LogCategory.REVIEW ? (
          <Pen className={'-translate-x-2 flex items-center rounded-md gap-x-2 bg-yellow-500 w-8 h-8 p-2 text-white'} />
        ) : (
          <PenOff className={'-translate-x-2 flex items-center rounded-md gap-x-2 bg-red-500 w-8 h-8 p-2 text-white'} />
        )}
        <TimelineTitle>
          <div className="flex gap-x-2 items-center">
            <Avatar className="h-8 w-8 text-sm">
              <AvatarFallback
                className={cn(
                  'font-semibold bg-gray-medium text-white',
                  user?.role === UserType.ORGANIZER ? 'bg-brand-medium' : 'bg-brand'
                )}
              >
                {user?.username?.substring(0, 2)?.toUpperCase() ?? 'U'}
              </AvatarFallback>
            </Avatar>
            {user?.username ?? <Label name="deleted_user" groupName={LabelGroup.GLOBAL} />}{' '}
            {category?.startsWith('Draft') && (
              <span className="text-xs bg-sky-500 text-white p-1 rounded-md">
                <Label name="draft_version" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
              </span>
            )}
            {by !== user?.userId && editor ? (
              <>
                <Label name="locked_up_editing" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
                <div className="flex items-center gap-2">
                  <Avatar className="h-8 w-8 text-sm">
                    <AvatarFallback className={cn('font-semibold  text-brand  bg-brand-medium')}>
                      {(editor?.username || editor?.name)?.substring(0, 2)?.toUpperCase() ?? 'U'}
                    </AvatarFallback>
                  </Avatar>
                  {editor?.username || editor?.name}
                </div>
                <Label name="ended_review" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
              </>
            ) : (
              <Label name={category + '_log'} groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
            )}
          </div>
        </TimelineTitle>
        <TimelineTime className="translate-x-24 md:-translate-x-20 flex items-center gap-x-2">
          <ClockIcon className="w-4 h-4" />
          {createdAtTime}
        </TimelineTime>
      </TimelineHeader>
    </TimelineItem>
  );
}
