import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { label } from '@/utils/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { useSettings } from '@/features/settings/api/getSettings';
import { Checkbox } from '@/components/ui/checkbox';
import { UseFormReturn } from 'react-hook-form';
import { z } from 'zod';
import { editEventSchema } from '../../data/edit-event-schema';
import { Asterisk } from '@/components/ui/asterisk';
import { Separator } from '@/components/ui/separator';
import { EventTimeInput } from '../event-time-input';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { cn } from '@/utils';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { UserType } from '@/features/user';
import { useMe } from '@/features/auth/api/get-me';
import { Button } from '@/components/ui/button';
import { X } from 'lucide-react';

interface BasicDetailsSectionProps {
  form: UseFormReturn<z.infer<typeof editEventSchema> | z.infer<typeof editEventSchema>> | any;
  defaultCollapsed?: boolean;
}

export function BasicDetailsSection({ form, defaultCollapsed = false }: BasicDetailsSectionProps) {
  const { data: settings } = useSettings({});

  const { data: user } = useMe({});
  const userType = user?.role;

  const labelGroup = userType === UserType.ADMIN || userType === UserType.MANAGER ? LabelGroup.MANAGER_EVENT_HANDLING : LabelGroup.ORGANIZER_EVENT_FORM;

  const days = settings?.day ?? [];
  const languages = settings?.languages?.[0]?.value?.split(';') ?? [];
  const topics = settings?.topic ?? [];
  const eventType = settings?.eventType ?? [];
  const organizationTypes = settings?.organizationType ?? [];

  // const comments = useEventStore((state) => state.comments);
  // const isAssigned = useEventStore((state) => state.isAssigned);
  // const generalComments = comments?.filter((comment) => comment.logText.Field === 'general');

  if (!settings) return null;

  return (
    <div className="space-y-6">
      {/* {isAssigned && (
        <FormField
          control={form.control}
          name="generalComment"
          render={({ field }) => (
            <FormItem className="bg-card rounded-md p-4 px-4 border">
              <FormLabel>
                <Label name="comment_for_general" groupName={labelGroup} />
              </FormLabel>
              <FormControl className="w-[300px]">
                <CommentField field={field} comments={generalComments ?? []} />
              </FormControl>
            </FormItem>
          )}
        />
      )} */}
      <Card className={cn('space-y-6 ')}>
        <Accordion type="single" collapsible defaultValue={defaultCollapsed ? '' : 'item-1'}>
          <AccordionItem className="[&[data-state=open]>.card-header]:bg-gray-medium [&[data-state=open]>.card-header]:text-white [&[data-state=open]>.card-header]:rounded-t-md" value="item-1">
            <CardHeader className="py-3">
              <AccordionTrigger className="text-md py-0">
                <CardTitle>
                  <Label name="event_basic_details_title" groupName={labelGroup} />
                </CardTitle>
              </AccordionTrigger>
            </CardHeader>
            <AccordionContent>
              <CardContent className="pt-6 text-sm space-y-6">
                <FormField
                  control={form.control}
                  name="organizationType"
                  render={({ field }) => (
                    <FormItem id="organizationType" className="flex items-start flex-col md:flex-row  md:items-center gap-4">
                      <FormLabel className="w-48 font-semibold">
                        <Label name="event_organization_type" groupName={labelGroup} />
                        <Asterisk className="ml-2" />
                      </FormLabel>
                      <Select onValueChange={field.onChange} value={field.value}>
                        <FormControl>
                          <SelectTrigger className="mt-[0_!important] [&>span]:text-left w-full md:w-full max-w-96">
                            <SelectValue placeholder={label('select_event_organization_type_placeholder', labelGroup)} />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {organizationTypes
                            .sort((a, b) => a.value.localeCompare(b.value))
                            .map(({ value }) => (
                              <SelectItem key={value} value={value}>
                                <Label name={'event_organization_type_option_' + value} groupName={labelGroup} />
                              </SelectItem>
                            ))}
                        </SelectContent>
                      </Select>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="eventType"
                  render={({ field }) => (
                    <FormItem id="eventType" className="flex items-start flex-col md:flex-row  md:items-center gap-4">
                      <FormLabel className="w-48 font-semibold">
                        <Label name="event_type" groupName={labelGroup} />
                        <Asterisk className="ml-2" />
                      </FormLabel>
                      <Select onValueChange={field.onChange} value={field.value}>
                        <FormControl>
                          <SelectTrigger className="mt-[0_!important] w-full md:w-full max-w-96">
                            <SelectValue placeholder={<Label name="select_event_type_placeholder" groupName={labelGroup} />} />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {eventType
                            .sort((a, b) => a.value.localeCompare(b.value))
                            .map(({ value }) => (
                              <SelectItem key={value} value={value}>
                                <Label name={'event_type_option_' + value} groupName={labelGroup} />
                              </SelectItem>
                            ))}
                        </SelectContent>
                      </Select>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="topic"
                  render={({ field }) => (
                    <FormItem id="topic" className="flex items-start flex-col md:flex-row  md:items-center gap-4">
                      <FormLabel className="w-48 font-semibold">
                        <Label name="event_topic" groupName={labelGroup} />
                        <Asterisk className="ml-2" />
                      </FormLabel>
                      <Select onValueChange={field.onChange} value={field.value}>
                        <FormControl>
                          <SelectTrigger className="mt-[0_!important] w-full max-w-96">
                            <SelectValue placeholder={<Label name="select_event_topic_placeholder" groupName={labelGroup} />} />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {topics
                            .sort((a, b) => a.value.localeCompare(b.value))
                            .map(({ value }) => (
                              <SelectItem key={value} value={value}>
                                <Label name={'event_topic_option_' + value} groupName={labelGroup} />
                              </SelectItem>
                            ))}
                        </SelectContent>
                      </Select>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="topic2"
                  render={({ field }) => (
                    <FormItem className="flex items-start flex-col md:flex-row  md:items-center  gap-4">
                      <FormLabel className="w-48 font-semibold">
                        <Label name="event_topic2" groupName={labelGroup} />
                      </FormLabel>
                      <div className="relative w-full max-w-96 ">
                        <Select onValueChange={field.onChange} value={field.value}>
                          <FormControl>
                            <SelectTrigger className="mt-[0_!important] w-full max-w-96 ">
                              <SelectValue placeholder={<Label name="select_event_topic2_placeholder" groupName={labelGroup} />} />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {topics
                              .sort((a, b) => a.value.localeCompare(b.value))
                              .map(({ value }) => (
                                <SelectItem key={value} value={value}>
                                  <Label name={'event_topic_option_' + value} groupName={labelGroup} />
                                </SelectItem>
                              ))}
                          </SelectContent>
                        </Select>
                        {!!field.value && (
                          <Button
                            variant={'ghost'}
                            className="absolute z-20 p-1 h-6 w-6 right-8 top-1/2  -translate-y-1/2"
                            onClick={e => {
                              e.stopPropagation();
                              field.onChange('');
                            }}>
                            <X className="w-4 h-4 text-slate-500 " />
                          </Button>
                        )}
                      </div>

                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="languages"
                  render={({ field }) => {
                    return (
                      <FormItem id="languages" className="flex items-start flex-col md:flex-row  md:items-center gap-4">
                        <FormLabel className="w-48 font-semibold">
                          <Label name="event_languages" groupName={labelGroup} />
                          <Asterisk className="ml-2" />
                        </FormLabel>
                        <div className="flex gap-x-4">
                          {languages.map(item => (
                            <div className="flex gap-x-2 items-center" key={item}>
                              <FormControl>
                                <Checkbox
                                  checked={field.value?.includes(item)}
                                  onCheckedChange={checked => {
                                    return checked ? field.onChange([item]) : field.onChange([]);
                                  }}
                                />
                              </FormControl>
                              <FormLabel className="font-normal">
                                <Label name={'event_language_option_' + item} groupName={labelGroup} />
                              </FormLabel>
                            </div>
                          ))}
                        </div>
                      </FormItem>
                    );
                  }}
                />
                <Separator />
                <FormField control={form.control} name="times" render={({ field }) => <EventTimeInput field={field} days={days} form={form} />} />
              </CardContent>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </Card>
    </div>
  );
}
