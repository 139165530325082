import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { Card, CardContent } from '@/components/ui/card';
import {
  TimelineConnector,
  TimelineContent,
  TimelineHeader,
  TimelineItem,
  TimelineTime,
  TimelineTitle,
} from '@/components/ui/timeline';
import { EventLog, LogEventUpdated } from '@/features/event/types';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { useLocations } from '@/features/location/api/getLocations';
import { UserType } from '@/features/user';
import { cn } from '@/utils';
import { convertPascalToCamel } from '@/utils/convert-pascal-to-camel-case';
import { convertUTCtoLocal } from '@/utils/convertUtcToLocal';
import { formateTime } from '@/utils/formatTime';
import { label } from '@/utils/label';
import { ClockIcon } from 'lucide-react';
import { PencilSquare } from 'react-bootstrap-icons';

export function UpdatedTimelineItem({ log }: { log: EventLog }) {
  const { user, logText, inserted } = log;

  const data = convertPascalToCamel<LogEventUpdated>(JSON.parse(logText ?? '{}'));
  const createdAtTime = formateTime(convertUTCtoLocal(inserted));

  const { data: locations } = useLocations({});

  const valueConverter = (value: string | null) => {
    if (data?.field === 'LocationId') {
      const location = locations?.find((location) => location.locationId === Number(value));
      return location?.name ?? value;
    }

    if (data?.field === 'Languages' && value) {
      return label('event_language_option_' + value, LabelGroup.MANAGER_EVENT_HANDLING);
    }

    if (value && ['True', 'False'].includes(value)) {
      return value === 'True' ? 'Ja' : 'Nej';
    }

    return value;
  };

  if (data?.from === null && data?.to === '') return null;

  return (
    <TimelineItem>
      <TimelineConnector className="bg-gray-medium/20" />
      <TimelineHeader>
        <PencilSquare
          className={'-translate-x-2 flex items-center rounded-md gap-x-2 bg-pink-500 w-8 h-8 p-2 text-white'}
        />
        <TimelineTitle>
          <div className="flex gap-x-2 items-center flex-wrap">
            <Avatar className="h-8 w-8 text-sm">
              <AvatarFallback
                className={cn(
                  'font-semibold bg-gray-medium text-white',
                  user?.userType === UserType.ORGANIZER ? 'bg-brand-medium text-brand' : 'bg-brand'
                )}
              >
                {user?.username?.substring(0, 2)?.toUpperCase() ?? 'U'}
              </AvatarFallback>
            </Avatar>
            {user?.username ?? <Label name="deleted_user" groupName={LabelGroup.GLOBAL} />}{' '}
            {log?.category?.startsWith('Draft') && (
              <span className="text-xs bg-sky-500 text-white p-1 rounded-md">
                <Label name="draft_version" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
              </span>
            )}
            <Label name={'updated_field_' + data.field} groupName={LabelGroup.MANAGER_EVENT_HANDLING} />{' '}
          </div>
        </TimelineTitle>
        <TimelineTime className="translate-x-24 md:-translate-x-20 flex items-center gap-x-2">
          <ClockIcon className="w-4 h-4" />
          {createdAtTime}
        </TimelineTime>
      </TimelineHeader>
      <TimelineContent className="ml-8 w-full">
        <Card className="w-fit max-w-xl">
          <CardContent className="p-3 italic gap-x-0  flex items-start text-left w-full divide-x divide-input ">
            <span className="w-fit break-words max-w-full px-3 space-y-2">
              <Label name="from" groupName={LabelGroup.GLOBAL} />
              <div>
                {data.from !== '' && data.from !== null ? (
                  <span className="break-words opacity-70">{valueConverter(data.from)}</span>
                ) : (
                  <Label name="empty" groupName={LabelGroup.GLOBAL} />
                )}
              </div>
            </span>
            <span className="w-fit max-w-full break-words px-4 space-y-2">
              <Label name="to" groupName={LabelGroup.GLOBAL} />
              <div>{valueConverter(data.to)}</div>
            </span>
          </CardContent>
        </Card>
      </TimelineContent>
    </TimelineItem>
  );
}
