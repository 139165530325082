import { Suspense } from 'react';
import { Sidebar } from './sidebar';
import { cn } from '@/utils';
import { Outlet, useLocation } from 'react-router-dom';
import { SidebarProvider } from '@/components/ui/sidebar';

export function Layout() {
  const { pathname } = useLocation();

  return (
    <SidebarProvider>
      <Sidebar />
      {/* <Header isCollapsed={isCollapsed} /> */}
      <main className={cn('min-h-screen w-full max-w-full overflow-hidden', pathname?.includes('events/manage') ? 'pt-0 pl-0' : 'p-6')}>
        <Suspense fallback={<div className="h-full w-full flex items-center justify-center "></div>}>
          <Outlet />
        </Suspense>
      </main>
    </SidebarProvider>
  );
}
