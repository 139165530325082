import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { FormControl, FormDescription, FormField, FormLabel } from '@/components/ui/form';
import { EventStatus } from '../../types';
import { Button } from '@/components/ui/button';
import { z } from 'zod';
import { Checkbox } from '@/components/ui/checkbox';
import { useEffect, useState } from 'react';

import { ValidationAnchorList } from '../validation-anchor-list';
import { useFormContext, useWatch } from 'react-hook-form';
import { Spinner } from '@/components/ui/spinner';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { sendEventSchema } from '../../data/send-event-schema';
import { useEndSession } from '../../api/end-session';
import { useNavigate } from 'react-router-dom';

interface SendSectionProps {
  isUpdating: boolean;
  isSending: boolean;
  isFreeOfCharge: boolean;
}

export function SendSection({ isUpdating, isSending, isFreeOfCharge }: SendSectionProps) {
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState<boolean>(false);
  const [errors, setErrors] = useState<any[]>([]);

  const form = useFormContext();
  const navigate = useNavigate();

  const formValues = useWatch({
    control: form.control
  });

  const { mutateAsync: endSession } = useEndSession({ eventId: formValues.eventId });

  const hasSubmitted = form.formState.submitCount > 0;

  useEffect(() => {
    if (formValues && hasSubmitted) {
      try {
        sendEventSchema.parse(formValues);
        setErrors([]);
      } catch (err) {
        if (err instanceof z.ZodError) {
          console.log(err);
          const messages = err.errors.map(error => ({
            message: error.message,
            section: (
              error as z.ZodIssue & {
                params: { section: string };
              }
            )?.params?.section,
            id: (
              error as z.ZodIssue & {
                params: { id: string };
              }
            )?.params?.id
          }));
          setErrors(messages);
        } else {
          console.error(err);
        }
      }
    }
  }, [formValues, hasSubmitted]);

  const hasError = errors.length > 0;
  const isAwatingPayment = form.watch('status') === EventStatus.AwaitingPayment;

  const isDirty = Object.keys(form.formState.dirtyFields).length > 0;
  const isValid = form.formState.isValid || Object.keys(form.formState.errors).length === 0;

  const hasEditPermission = form.watch('status') !== EventStatus.Pending;
  const cardTitle = !hasEditPermission ? 'event_review_title' : 'event_send_title';

  const proceedToPayment = async () => {
    try {
      await endSession(form.watch('eventId'));
      navigate(`/payment`, { replace: true });
    } catch (error) {
      console.error(error);
    }
  };

  const renderConditionalContent = () => {
    if (isAwatingPayment) {
      return isDirty ? (
        <FormDescription className="text-destructive flex flex-col gap-2">
          <span className="flex gap-2">
            * <Label name="save_before_proceeding" groupName={LabelGroup.ORGANIZER_EVENT_FORM} />
          </span>
        </FormDescription>
      ) : (
        <FormDescription className="text-destructive flex flex-col gap-2">
          <span className="flex gap-2">
            * <Label name="event_send_payment_required" groupName={LabelGroup.ORGANIZER_EVENT_FORM} />
          </span>
        </FormDescription>
      );
    }

    return (
      <>
        <div className="flex flex-row items-start space-x-3">
          <FormControl>
            <Checkbox className="mt-0.5" checked={hasAcceptedTerms} onCheckedChange={checked => setHasAcceptedTerms(!!checked)} />
          </FormControl>
          <FormLabel className="leading-normal">
            <Label name="event_terms_of_agreement" groupName={LabelGroup.ORGANIZER_EVENT_FORM} />
          </FormLabel>
        </div>
        {errors.length > 0 && <ValidationAnchorList errors={errors} />}
      </>
    );
  };

  if (form.watch('status') === EventStatus.Cancelled) {
    return null;
  }

  return (
    <Card id="send-section">
      <CardHeader>
        <CardTitle>
          <Label name={cardTitle} groupName={LabelGroup.ORGANIZER_EVENT_FORM} />
        </CardTitle>
      </CardHeader>

      {hasEditPermission && (
        <CardContent className="text-sm space-y-6">
          <p className="text-sm">
            <Label name="event_send_description" groupName={LabelGroup.ORGANIZER_EVENT_FORM} />
          </p>

          {renderConditionalContent()}

          <div className="flex items-start md:items-center md:flex-row flex-col gap-2">
            {!isAwatingPayment || isFreeOfCharge ? (
              <FormField
                control={form.control}
                name="sendToReview"
                render={({ field }) => (
                  <Button size="sm" disabled={!hasAcceptedTerms || isSending || isUpdating} variant="primary" type="submit" onClick={() => hasAcceptedTerms && field.onChange(true)}>
                    {isSending && <Spinner className="mr-2 text-primary-foreground h-4 w-4" />}
                    <Label name="event_send_review_button_text" groupName={LabelGroup.ORGANIZER_EVENT_FORM} />
                  </Button>
                )}
              />
            ) : (
              <Button size="sm" type="button" disabled={hasError || isDirty || !isValid} onClick={proceedToPayment}>
                <Label name="event_payment_button_text" groupName={LabelGroup.ORGANIZER_EVENT_FORM} />
              </Button>
            )}

            <Button size="sm" disabled={isUpdating || !form.formState.isDirty || isSending} onClick={() => form.setValue('sendToReview', false)} type="submit">
              {isUpdating && !form.watch('sendToReview') && <Spinner className="mr-2 text-primary-foreground h-4 w-4" />}
              <Label name="event_save_button_text" groupName={LabelGroup.ORGANIZER_EVENT_FORM} />
            </Button>
          </div>
        </CardContent>
      )}
    </Card>
  );
}
