import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { PricePeriodSettings } from '../components/price-period-settings';
import { Separator } from '@/components/ui/separator';
import { useSettings } from '../api/getSettings';
import { DeadlineSettings } from '../components/deadline-settings';
import { EmailSettings } from '../components/email-settings';
import { Calendar, CalendarRange, DollarSign, Mail, MessageCircle } from 'lucide-react';
import { YearSettings } from '../components/year-settings';
import { CalendarDate } from 'react-bootstrap-icons';
import { EventDaysSettings } from '../components/event-days-settings';
import { GroundLocationSettings } from '../components/ground-location-settings';
import { BannerSettings } from '../components/banner-settings';

export function Settings() {
  const { isLoading } = useSettings({});

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="space-y-6 w-full">
      <div>
        <h2 className="text-xl font-semibold">
          <Label name="Settings" groupName={LabelGroup.ADMIN_SETTINGS} />
        </h2>
        <p className="text-muted-foreground text-sm">
          <Label name="SettingsDescription" groupName={LabelGroup.ADMIN_SETTINGS} />
        </p>
      </div>

      <Separator />
      <div className="flex gap-4 items-start justify-start w-full">
        <Tabs defaultValue="price_period" className="w-full flex gap-10 items-start ">
          <TabsList className="flex flex-col bg-transparent [&>div]:h-full  gap-2 h-auto text-black">
            <TabsTrigger
              value="price_period"
              className="flex justify-start data-[state=active]:shadow-none w-64 data-[state=active]:bg-brand py-2 data-[state=active]:text-white"
            >
              <DollarSign className="mr-2 h-4 w-4" />
              <Label name="PricePeriodSettings" groupName={LabelGroup.ADMIN_SETTINGS} />
            </TabsTrigger>
            <TabsTrigger
              value="deadline"
              className="flex justify-start data-[state=active]:shadow-none w-64 data-[state=active]:bg-brand py-2 data-[state=active]:text-white"
            >
              <Calendar className="mr-2 h-4 w-4" />
              <Label name="DeadlineSettings" groupName={LabelGroup.ADMIN_SETTINGS} />
            </TabsTrigger>
            <TabsTrigger
              value="email"
              className="flex justify-start data-[state=active]:shadow-none data-[state=active]:bg-brand py-2 data-[state=active]:text-white w-64"
            >
              <Mail className="mr-2 h-4 w-4" />
              <Label name="EmailSettings" groupName={LabelGroup.ADMIN_SETTINGS} />
            </TabsTrigger>
            <TabsTrigger
              value="banner"
              className="flex justify-start data-[state=active]:shadow-none data-[state=active]:bg-brand py-2 data-[state=active]:text-white w-64"
            >
              <MessageCircle className="mr-2 h-4 w-4" />
              <Label name="banner" groupName={LabelGroup.ADMIN_SETTINGS} />
            </TabsTrigger>
            {/* <TabsTrigger
              value="markplatser"
              className="flex justify-start data-[state=active]:shadow-none data-[state=active]:bg-brand py-2 data-[state=active]:text-white w-64"
            >
              <MapPin className="mr-2 h-4 w-4" />
              <Label name="ground_locations" groupName={LabelGroup.ADMIN_SETTINGS} />
            </TabsTrigger> */}
            <TabsTrigger
              value="event_days"
              className="flex justify-start data-[state=active]:shadow-none data-[state=active]:bg-brand py-2 data-[state=active]:text-white w-64"
            >
              <CalendarDate className="mr-2 h-4 w-4" />
              <Label name="event_days" groupName={LabelGroup.ADMIN_SETTINGS} />
            </TabsTrigger>
            <TabsTrigger
              value="year"
              className="flex justify-start data-[state=active]:shadow-none data-[state=active]:bg-brand py-2 data-[state=active]:text-white w-64"
            >
              <CalendarRange className="mr-2 h-4 w-4" />
              <Label name="year_settings" groupName={LabelGroup.ADMIN_SETTINGS} />
            </TabsTrigger>
          </TabsList>
          <TabsContent value="price_period">
            <PricePeriodSettings />
          </TabsContent>
          <TabsContent value="deadline">
            <DeadlineSettings />
          </TabsContent>
          <TabsContent value="email">
            <EmailSettings />
          </TabsContent>
          <TabsContent value="markplatser">
            <GroundLocationSettings />
          </TabsContent>
          <TabsContent value="event_days">
            <EventDaysSettings />
          </TabsContent>
          <TabsContent value="banner">
            <BannerSettings />
          </TabsContent>
          <TabsContent value="year">
            <YearSettings />
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
}
