import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Setting } from '../types';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useUpdateSetting } from '../api/update-setting';
import { useToast } from '@/components/ui/use-toast';
import { label } from '@/utils/label';
import TinyMCE from '@/components/tinyMCE';
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group';
import { cn } from '@/utils';
import { AlertCircle, Ban, Check, CheckCircle, CircleX } from 'lucide-react';
import { InfoCircle } from 'react-bootstrap-icons';
import { useSettings } from '../api/getSettings';
import { Checkbox } from '@/components/ui/checkbox';

const severties = {
  error: 'bg-destructive',
  warning: 'bg-amber-500',
  info: 'bg-sky-500',
  success: 'bg-emerald-500',
};

const icons = {
  error: <CircleX className="w-4 h-4 text-destructive" />,
  warning: <AlertCircle className="w-4 h-4 text-amber-500" />,
  info: <InfoCircle className="w-4 h-4 text-sky-500" />,
  success: <CheckCircle className="w-4 h-4 text-emerald-500" />,
};

const schema = z.object({
  settingId: z.number(),
  name: z.string(),
  value: z.string(),
  sortOrder: z.number(),
  data: z.object({
    title: z.string(),
    message: z.string(),
    icon: z.string(),
    severity: z.string(),
    hidden: z.boolean(),
  }),
});

export function BannerSettings() {
  const { data: settings } = useSettings({});

  const bannerSetings =
    Object.values(settings?.banner || []).map((value: Setting) => ({
      ...value,
      data: JSON.parse(value.data as string),
    }))?.[0] ?? {};

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    values: {
      ...bannerSetings,
    },
  });

  const settingMutation = useUpdateSetting({});
  const { toast } = useToast();

  const handleSubmit = async (values: z.infer<typeof schema>) => {
    try {
      await settingMutation.mutateAsync({ ...values, data: JSON.stringify(values.data) });

      toast({
        variant: 'success',
        title: label('setting_updated', LabelGroup.ADMIN_SETTINGS),
      });
    } catch (error) {
      toast({
        variant: 'destructive',
        title: label('setting_update_failed', LabelGroup.ADMIN_SETTINGS),
      });
      console.error('Error updating settings', error);
    }
  };

  return (
    <Form {...form}>
      <form
        onKeyDown={(event) => event.key === 'Enter' && event.preventDefault()}
        onSubmit={form.handleSubmit(handleSubmit)}
        className="space-y-8"
      >
        <Card className="w-[600px]">
          <CardHeader>
            <CardTitle>
              <Label name="banner_settings_title" groupName={LabelGroup.ADMIN_SETTINGS} />
            </CardTitle>
            <CardDescription>
              <Label name={'banner_settings_description'} groupName={LabelGroup.ADMIN_SETTINGS} />
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-6">
            <FormField
              control={form.control}
              name="data.title"
              render={({ field }) => (
                <FormItem className="max-w-sm">
                  <FormLabel>
                    <Label name="title" groupName={LabelGroup.ADMIN_SETTINGS} />
                  </FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="data.message"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>
                    <Label name="message" groupName={LabelGroup.ADMIN_SETTINGS} />
                  </FormLabel>
                  <FormControl className="font-base">
                    <TinyMCE
                      onChange={field.onChange}
                      value={field.value}
                      config={{
                        inline: false,
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="data.severity"
              render={({ field }) => (
                <FormItem className="w-full flex items-center">
                  <FormLabel className="w-48">
                    <Label name="severity" groupName={LabelGroup.ADMIN_SETTINGS} />
                  </FormLabel>
                  <FormControl className="font-base">
                    <ToggleGroup
                      type="single"
                      onChange={(value) => {
                        alert(value);
                        field.onChange(value);
                      }}
                    >
                      {['error', 'warning', 'info', 'success'].map((severity) => (
                        <ToggleGroupItem
                          key={severity}
                          value={severity}
                          onClick={() => field.onChange(severity)}
                          className={cn(
                            severties[severity as keyof typeof severties],
                            'h-[40px] w-[42px] relative',
                            'hover:' + severties[severity as keyof typeof severties],
                            'data-[state=on]:' + severties[severity as keyof typeof severties]
                          )}
                        >
                          {field.value === severity && <Check className="w-4 h-4 absolute  text-white" />}
                        </ToggleGroupItem>
                      ))}
                    </ToggleGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="data.icon"
              render={({ field }) => (
                <FormItem className="w-full flex items-center">
                  <FormLabel className="w-48">
                    <Label name="icon" groupName={LabelGroup.ADMIN_SETTINGS} />
                  </FormLabel>
                  <FormControl className="font-base">
                    <ToggleGroup type="single" value={field.value} onChange={field.onChange}>
                      {['error', 'warning', 'info', 'success', null].map((icon) => (
                        <ToggleGroupItem
                          onClick={() => field.onChange(icon)}
                          key={icon}
                          value={icon ?? 'none'}
                          className="border"
                        >
                          {icons[icon as keyof typeof icons] ?? <Ban className="w-4 h-4 text-primary" />}
                        </ToggleGroupItem>
                      ))}
                    </ToggleGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="data.hidden"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <div className="flex items-center gap-4">
                      <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                      <label className="mt-0!">
                        <Label name="hide_banner" groupName={LabelGroup.ADMIN_SETTINGS} />
                      </label>
                    </div>
                  </FormControl>
                </FormItem>
              )}
            />
          </CardContent>
          <CardFooter>
            <Button disabled={!form.formState.isDirty} type="submit">
              <Label name="Save" groupName={LabelGroup.GLOBAL} />
            </Button>
          </CardFooter>
        </Card>
      </form>
    </Form>
  );
}
