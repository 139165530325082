import { Button } from '@/components/ui/button';
import { EventStatus } from '@/features/event/types';
import { useState } from 'react';
import { eventReviewSchema } from '../data/event-review-schema';
import { UseFormReturn } from 'react-hook-form';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { FormControl } from '@/components/ui/form';
import { cn } from '@/utils';
import { CalendarIcon } from 'lucide-react';
import { Calendar } from '@/components/ui/calendar';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { NotifyChange } from './notify-change';

interface ReturnDueDialogProps {
  form: UseFormReturn<Zod.infer<typeof eventReviewSchema>>;
  previousStatus: EventStatus | undefined;
  onSubmit: any;
}

export function ReturnDueDialog({ form, previousStatus, onSubmit }: ReturnDueDialogProps) {
  const [dueDays, setDueDays] = useState(form.watch('returnDue') ?? 7);

  const handleSubmit = () => {
    form.setValue('returnDue', Number(dueDays), { shouldDirty: false });

    if(form.watch('notify') === null) {
      form.setValue('notify', false, { shouldDirty: false });
    }

    form.trigger();
    onSubmit();
  };

  const onClose = () => {
    form.reset(
      {
        ...form.getValues(),
        returnDue: null,
        status: previousStatus,
      },
      {
        keepDirty: true,
        keepIsSubmitted: false,
      }
    );
    form.trigger();
  };

  const handleReturnDueChange = (date: Date | undefined) => {
    if (!date) {
      return;
    }

    const returnDate = new Date(form.watch('returnDate') ?? new Date());
    const differenceInDays = Math.ceil((date.getTime() - returnDate.getTime()) / (1000 * 60 * 60 * 24));

    // If the selected date is the same as returnDate, set returnDue to 0
    if (differenceInDays === 0) {
      setDueDays(0);
    } else {
      setDueDays(differenceInDays);
    }
  };

  return (
    <div className="fixed h-screen w-screen z-40 left-0 top-0 ">
      <div className="bg-black/70 w-screen h-screen"></div>
      <Card className="absolute max-w-md left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
        <CardHeader>
          <CardTitle>
            <Label name="return_due" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
          </CardTitle>
          <CardDescription>
            <Label name="return_due_description" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
          </CardDescription>
        </CardHeader>
        <CardContent className='space-y-4'>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button variant={'outline'} className={cn('w-[240px] pl-3 text-left font-normal')}>
                  {new Date(
                    +new Date(form.watch('returnDate') ?? new Date()) +
                      (dueDays > 0 ? 1000 * 60 * 60 * 24 * dueDays : 0)
                  ).toLocaleDateString('sv-SE', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  })}

                  <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <Calendar
                mode="single"
                selected={
                  new Date(
                    +new Date(form.watch('returnDate') ?? new Date()) +
                      (dueDays > 0 ? 1000 * 60 * 60 * 24 * dueDays : 0)
                  )
                }
                onSelect={handleReturnDueChange}
                disabled={(date) => date <= new Date(new Date().setDate(new Date().getDate() - 1))}
                initialFocus
              />
            </PopoverContent>
          </Popover>
          <NotifyChange form={form} dialog={false} onSubmit={() => {}} />
        </CardContent>

        <CardFooter className="flex justify-end gap-x-2">
          <Button type="button" variant="outline" onClick={onClose}>
            <Label name="cancel" groupName={LabelGroup.GLOBAL} />
          </Button>
          <Button disabled={dueDays === null} onClick={handleSubmit}>
            <Label name="update" groupName={LabelGroup.GLOBAL} />
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
}
