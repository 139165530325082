import { cn } from '@/utils';
import { useEffect, useRef, useState } from 'react';
import { sections } from '../data/sections';
import { Check2Circle, ExclamationCircle } from 'react-bootstrap-icons';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { useFormContext, useWatch } from 'react-hook-form';
import { sendEventSchema } from '../data/send-event-schema';
import { z } from 'zod';
import { Button } from '@/components/ui/button';
import { Spinner } from '@/components/ui/spinner';
import { EventStatus, TEvent } from '../types';
import { Files } from 'lucide-react';
import { scrollToMiddle } from '../utils/scroll-to-middle';
import { baseEventSchema } from '../data/base-event-schema';

interface NavigationTabsProps {
  scrollAreaRef: any;
  isUpdating: boolean;
  event?: TEvent;
}

export function NavigationTabs({ scrollAreaRef, isUpdating, event }: NavigationTabsProps) {
  const [errors, setErrors] = useState<any[]>([]);
  const [isFixed, setIsFixed] = useState(false); // State to control the fixed position
  const [activeSectionId, setActiveSectionId] = useState('');
  const containerRef = useRef<HTMLDivElement>(null);
  const tabViewRef = useRef<HTMLDivElement>(null);

  const filteredSections = sections;

  const form = useFormContext();
  const formValues = useWatch({ control: form.control });
  const submitCount = form.formState.submitCount;

  const isSending = form.watch('sendToReview');

  const schema = (() => {
    
    if (!isSending && form.watch('status') === EventStatus.Draft) {
      console.log('isSending', isSending,form.watch('status') === EventStatus.Draft);
      return baseEventSchema;
    }
    return sendEventSchema;
  })();


  const sectionElements = sections.map(section => document.querySelector(`#${section.id}`));

  useEffect(() => {
    if (formValues && submitCount > 0) {
      try {
        schema.parse(formValues);
        setErrors([]);
      } catch (err) {
        if (err instanceof z.ZodError) {
          const messages = err.errors.map(error => ({
            message: error.message,
            id: (
              error as z.ZodIssue & {
                params: { id: string };
              }
            )?.params?.id,
            section: (
              error as z.ZodIssue & {
                params: { section: string };
              }
            )?.params?.section
          }));
          setErrors(messages);
        } else {
          console.error(err);
        }
      }
    }
  }, [formValues, submitCount]);

  // Effect to update active section based on scroll position
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        // Filter out entries that are not intersecting
        const intersectingEntries = entries.filter(entry => entry.isIntersecting);

        // Sort the intersecting entries by their top position (the closest to the top will be first)
        intersectingEntries.sort((a, b) => a.boundingClientRect.top - b.boundingClientRect.top);

        if (intersectingEntries.length > 0) {
          // Set the active section to the one closest to the top
          setActiveSectionId(intersectingEntries[0].target.id);
        }
      },
      {
        root: null,
        rootMargin: '0px 0px 0px 0px', // Adjust rootMargin if needed
        threshold: 0.2 // Trigger when 10% of the section is visible
      }
    );

    sectionElements.forEach(sectionElement => {
      if (sectionElement) {
        observer.observe(sectionElement);
      }
    });

    return () => {
      // Cleanup observers when the component unmounts
      sectionElements.forEach(sectionElement => {
        if (sectionElement) {
          observer.unobserve(sectionElement);
        }
      });
    };
  }, [sections, activeSectionId, sectionElements]);

  useEffect(() => {
    if (activeSectionId) {
      const tab = document.getElementById('tab-' + activeSectionId);
      if (tab && tabViewRef.current) {
        // Scroll the tabView to center the active tab
        tabViewRef.current.scrollTo({
          left: tab.offsetLeft - tabViewRef.current.offsetWidth / 2 + tab.offsetWidth / 2,
          behavior: 'smooth'
        });
      }
    }
  }, [activeSectionId]);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        if (scrollAreaRef.scrollTop > 100) {
          setIsFixed(true);
        } else {
          setIsFixed(false);
        }
      }
    };

    // Add scroll event listener

    if (scrollAreaRef) {
      scrollAreaRef.addEventListener('scroll', handleScroll);
    }

    // Cleanup event listener on unmount
    return () => {
      if (scrollAreaRef) {
        scrollAreaRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [scrollAreaRef, containerRef]);

  return (
    <div
      ref={containerRef}
      className={cn(
        'w-full bg-card h-fit block transition-transform duration-300',
        isFixed ? 'fixed top-0 z-50' : 'static' // Set to fixed only if more than 100px out of view
      )}>
      <div className=" p-4 space-y-2">
        <div className="flex items-center justify-between ">
          {form.watch('eventId') !== 0 && (
            <div className="text-xl font-bold font-header">
              <Label name="event_id_title" groupName={LabelGroup.ORGANIZER_EVENT_FORM} /> {event?.draftParentId ?? event?.eventId}
            </div>
          )}
          <Button variant="outline" size="sm" disabled={isUpdating || !form.formState.isDirty} type="submit">
            {isUpdating && !form.watch('sendToReview') && <Spinner className="mr-2 text-primary h-4 w-4" />}
            <Label name="event_save_button_text" groupName={LabelGroup.ORGANIZER_EVENT_FORM} />
          </Button>
        </div>
        {event?.draftParentId && (
          <div className="bg-brand/10 text-orange-900 text-sm font-semibold font-base flex items-center w-full p-2 gap-2 px-2 rounded-md">
            <Files className="h-4 w-4  stroke-brand" />
            <Label name="event_draft_version" groupName={LabelGroup.ORGANIZER_EVENT_LIST} />
          </div>
        )}
      </div>

      <div ref={tabViewRef} className="flex w-auto h-fit overflow-x-auto overflow-y-hidden text-sm divide-x divide-zinc-200 border font-semibold">
        {filteredSections.map(section => (
          <div key={section.id} id={'tab-' + section.id} className={cn('flex-shrink-0', activeSectionId === section.id ? 'border-b-4 !border-b-brand' : '')}>
            <div onMouseDown={() => scrollToMiddle(section.id)} role="navigation" id={'section-menu-item-' + section.id} className={cn('hover:bg-gray-light bg-card p-3 px-4 flex items-center gap-x-2 h-fit whitespace-nowrap')}>
              {errors?.some(error => error.section === section.id) ? <ExclamationCircle className="h-4 w-4 text-amber-500" /> : <Check2Circle className="h-4 w-4 text-success" />}

              <Label name={'section_menu_' + section.title} groupName={LabelGroup.ORGANIZER_EVENT_FORM} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
