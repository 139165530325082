import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';
import { useToast } from '@/components/ui/use-toast';
import { label } from '@/utils/label';
import { LabelGroup } from '@/features/label/types';

export type UpdatePasswordDTO = {
  userId: number;
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

export const updatePassword = (data: UpdatePasswordDTO): Promise<void> => {
  return axios.put(`/user/change-password`, { ...data });
};

type UseUpdatePasswordOptions = {
  config?: MutationConfig<typeof updatePassword>;
};

export const useUpdatePassword = ({ config }: UseUpdatePasswordOptions) => {
  const { toast } = useToast();

  return useMutation({
    onSuccess: () => {
      toast({
        variant: 'success',
        title: label('user_password_updated_snackbar_text', LabelGroup.ADMIN_USER_EDIT),
      });
    },
    ...config,
    mutationFn: updatePassword,
  });
};
