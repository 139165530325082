import { useMe } from '@/features/auth/api/get-me';
import { useEvents } from '../../api/getEvents';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { EventStatusBadge } from '@/features/event/components/event-status-badge';
import { Link } from 'react-router-dom';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Button } from '@/components/ui/button';

export function MyEvents() {
  const { data: user } = useMe({});

  const { data: events = [], isLoading } = useEvents({
    assignedToMe: false,
  });

  const myEvents = events?.filter((event) => event.assignedUserId === user?.userId);

  if (isLoading) return <Skeleton className="w-[600px] h-96" />;

  if (myEvents.length === 0) return null;

  return (
    <Card className="w-fit h-fit">
      <CardHeader>
        <CardTitle>
          <Label name="my_proceedings_title" groupName={LabelGroup.MANAGER_DASHBOARD} />
        </CardTitle>
      </CardHeader>
      <CardContent className="text-sm divide-y divide-input px-0">
        <ScrollArea className="max-h-96 overflow-y-auto px-4">
          <div className="divide-y border-y">
            {myEvents?.splice(0, 10)?.map((event) => (
              <div className="flex justify-between items-center gap-x-2 hover:bg-muted py-2 px-4 ">
                <Link
                  to={`/events/manage/${event.eventId}`}
                  key={event.eventId}
                  className="hover:underline cursor-pointer flex gap-x-2"
                >
                  <div className="font-bold">{event.eventId}</div>
                  <div className="max-w-96 break-words">{event.title}</div>
                </Link>
                <div className="text-sm">
                  <EventStatusBadge className="[&>label]:text-xs" status={event.status} />
                </div>
              </div>
            ))}
          </div>
        </ScrollArea>
      </CardContent>
      <CardFooter>
        <Link to="/events#assigned" className="text-sm ">
          <Button variant="outline">
            <Label name="view_all" groupName={LabelGroup.MANAGER_DASHBOARD} />
          </Button>
        </Link>
      </CardFooter>
    </Card>
  );
}
