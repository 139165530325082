import { ColumnDef } from '@tanstack/react-table';

import { DataTableColumnHeader } from '@/components/ui/data-table-column-header';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { Organization } from '@/features/organizer/types';
import { Link } from 'react-router-dom';
import { Check } from 'lucide-react';

export const organizerColumns: ColumnDef<Organization>[] = [
  {
    accessorKey: 'organizerName',
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        TitleComponent={<Label name="organizer_name" groupName={LabelGroup.ADMIN_ORGANIZATION_LIST} />}
      />
    ),
    cell: ({ row }) => (
      <Link to={`/organizations/${row.original.organizerId}`}>
        <span className="w-full underline my-2">{row.original.organizerName}</span>
      </Link>
    ),
    filterFn: (row, id, value) => {
      return value.toString().includes(row.getValue(id)?.toString());
    },
  },
  {
    accessorKey: 'mainContact',
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        TitleComponent={<Label name="main_contact" groupName={LabelGroup.ADMIN_ORGANIZATION_LIST} />}
      />
    ),
    cell: ({ row }) => (
      <span className=" w-full">{row.original.users?.find((user) => !!user.mainContact)?.user?.username}</span>
    ),
    filterFn: (row, id, value) => {
      return value.toString().includes(row.getValue(id)?.toString());
    },
  },
  {
    accessorKey: 'hasInvoice',
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        TitleComponent={<Label name="has_invoice" groupName={LabelGroup.ADMIN_ORGANIZATION_LIST} />}
      />
    ),
    cell: ({ row }) => (
      <span className=" w-full">
        {row.original.invoiceOrgNo && row.original?.invoiceName && <Check className="h-4 w-4 text-green-500" />}
      </span>
    ),
  },
];
