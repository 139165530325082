import { useForm } from 'react-hook-form';
import { useUpdateUser } from '../api/update-user';
import { useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { useUser } from '../api/get-user';
import { z } from 'zod';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { updateUserSchema } from '../data/update-user-schema';
import { UserType } from '../types';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { roles } from '../data/data';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';

export function User() {
  const { id } = useParams();

  const { data: user } = useUser({
    userId: Number(id),
    config: {
      enabled: !!id,
    },
  });

  const { mutateAsync: updateUser, isLoading: isUpdating } = useUpdateUser({
    userId: 1,
  });

  const form = useForm<z.infer<typeof updateUserSchema>>({
    resolver: zodResolver(updateUserSchema),
    values: {
      userId: user?.userId ?? 0,
      name: user?.name ?? '',
      username: user?.username ?? '',
      telephone: user?.telephone ?? '',
      role: user?.role ?? UserType.ADMIN,
    },
  });

  const handleSubmit = async (values: z.infer<typeof updateUserSchema>) => {
    await updateUser(values);
  };

  return (
    <div className="w-full max-w-md">
      <Card>
        <CardHeader>
          <CardTitle>
            <Label name="user_details_card_title" groupName={LabelGroup.ADMIN_USER_EDIT} />
          </CardTitle>
        </CardHeader>
        <CardContent>
          <Form {...form}>
              <form onKeyDown={(event) => event.key === 'Enter' && event.preventDefault()} onSubmit={form.handleSubmit(handleSubmit)} className="space-y-8">
              <FormField
                control={form.control}
                name="username"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>
                      <Label name="username" groupName={LabelGroup.ADMIN_USER_EDIT} />
                    </FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>
                      <Label name="name" groupName={LabelGroup.ADMIN_USER_EDIT} />
                    </FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="telephone"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>
                      <Label name="telephone" groupName={LabelGroup.ADMIN_USER_EDIT} />
                    </FormLabel>
                    <FormControl>
                      <Input {...field} type="telephone" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="role"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <Label name="user_role" groupName={LabelGroup.ADMIN_USER_EDIT} />
                    </FormLabel>
                    <Select onValueChange={(value) => field.onChange(Number(value))} value={field.value.toString()}>
                      <FormControl>
                        <SelectTrigger className="[&>span]:text-left ">
                          <SelectValue />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {roles.map(({ value }) => (
                          <SelectItem key={value} value={value.toString()}>
                            <Label name={'user_role_' + value} groupName={LabelGroup.ADMIN_USER_EDIT} />
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <Button disabled={isUpdating || form.formState.isSubmitting || !form.formState.isDirty} type="submit">
                <Label name={isUpdating ? 'updating' : 'update'} groupName={LabelGroup.ADMIN_USER_EDIT} />
              </Button>
            </form>
          </Form>
        </CardContent>
      </Card>
    </div>
  );
}
