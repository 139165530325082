import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';

import { TEvent as Event, EventTime } from '../types';
import { useToast } from '@/components/ui/use-toast';
import { label } from '@/utils/label';
import { LabelGroup } from '@/features/label/types';

const QUERY_KEY = 'events';

export interface CreateEventDTO extends Event {}

export const createEvent = (data: CreateEventDTO): Promise<Event> => {
  const times = data.times.reduce((acc: EventTime[], time: EventTime) => {
    if (time.eventStart && time.eventEnd) {
      const isEventStartISO = time.eventStart?.includes('T');
      const isEventEndISO = time?.eventEnd?.includes('T');

      // If both are in ISO format, return the original object
      if (isEventStartISO && isEventEndISO) {
        acc.push({
          ...time,
          inserted: new Date().toISOString() // Only update the inserted date
        });
        return acc;
      }

      const eventStart = new Date(time.eventStart);
      const eventEnd = new Date(time.eventEnd);

      // Convert to ISO strings directly without adjusting offsets
      const eventStartISO = eventStart.toISOString();
      const eventEndISO = eventEnd.toISOString();

      // Return a new object with the original time data plus the converted ISO strings
      acc.push({
        ...time,
        inserted: new Date().toISOString(),
        eventStart: eventStartISO,
        eventEnd: eventEndISO
      });
    }

    return acc;
  }, []);

  const formattedData = {
    ...data,
    languages: Array.isArray(data.languages) ? data.languages.join(';') : data.languages,
    times
  };

  return axios.post(`/event/${data.organizerId}`, formattedData);
};

type UseCreateEventOptions = {
  organizerId: number;
  config?: MutationConfig<typeof createEvent>;
};

export const useCreateEvent = ({ config, organizerId }: UseCreateEventOptions) => {
  const { toast } = useToast();

  return useMutation({
    onMutate: async next => {
      await queryClient.cancelQueries([QUERY_KEY]);

      const previousUsers = queryClient.getQueryData<Event[]>([QUERY_KEY]);

      queryClient.setQueryData([QUERY_KEY], [...(previousUsers || []), next]);

      return { previousUsers };
    },
    onError: (_, __, context: any) => {
      if (context?.previousUsers) {
        queryClient.setQueryData([QUERY_KEY], context.previousUsers);
      }
      toast({
        variant: 'destructive',
        title: label('something_went_wrong', LabelGroup.GLOBAL)
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEY]);
      queryClient.refetchQueries([QUERY_KEY]);

      toast({
        variant: 'success',
        title: label('event_created_successfully', LabelGroup.ORGANIZER_EVENT_CREATE)
      });
    },
    ...config,
    mutationFn: (data: any) =>
      createEvent({
        organizerId,
        ...data
      })
  });
};
