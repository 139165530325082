import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { eventReviewSchema } from '../data/event-review-schema';
import { Form, FormControl, FormField, FormItem, FormLabel } from '@/components/ui/form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { useEvent } from '../api/getEvent';
import { UserType } from '@/features/user';
import { Clock, Files } from 'lucide-react';
import { useUpdateReview } from '../api/updateReview';
import { useEventStore } from '@/stores/eventStore';
import { parseEventLogToComments } from '@/utils/parseEventLogToComments';
import { MangeEventSkeleton } from '../components/MangeEventSkeleton';
import { EventStatus } from '@/features/event/types';
import { OverviewMenu } from '../components/overview-menu';
import { sections } from '../data/sections';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { HistoryTimeline } from '../components/(history-timeline)/history-timeline';
import { OrganizerDetails } from '../components/organizer-details';
import { useMe } from '@/features/auth/api/get-me';
import { CommentField } from '../components/CommentField';
import { Comment } from '@/features/event/components/comment';
import { Pricing } from '../components/pricing';
import { AssignManager } from '../components/AssignManager';
import { ReturnDueDialog } from '../components/return-due-dialog';
import { useManagers } from '../api/getManagers';
import { EventStatusDropDown } from '../components/event-status-dropdown';
import { useStartSession } from '../../event/api/start-session';
import { VersionComparer } from '../components/version-comparer';
import { Spinner } from '@/components/ui/spinner';
import { formatDate } from '@/utils/formatDate';
import { NotifyChange } from '../components/notify-change';
import { useOrganization } from '@/features/organizer/api/getOrganization';
import { useEndSession } from '../../event/api/end-session';
import { ActiveEditorAlertDialog } from '../components/active-editor-alert-dialog';
import { usePrompt } from '@/hooks/use-prompt';
import { label } from '@/utils/label';
import { PreviewSection } from '@/features/event/components/(sections)/preview-section';
import { useEffect, useRef } from 'react';
import { cn } from '@/utils';

export function ManageEvent() {
  const { id } = useParams();

  const processingViewRef = useRef(null);
  const scrollInterval = useRef<any>(null);

  const setEventComments = useEventStore(state => state.setComments);
  const navigate = useNavigate();

  const comments = useEventStore(state => state.comments);

  const { data: managers } = useManagers({});
  const { data: user } = useMe({});

  const {
    data: event,
    isLoading,
    isFetched
  } = useEvent({
    eventId: Number(id),
    config: {
      enabled: !!id,
      onSuccess: data => {
        setEventComments(parseEventLogToComments(data?.logs ?? []));
      }
    }
  });

  const { data: organizer } = useOrganization({
    organizerId: Number(event?.organizerId),
    config: {
      enabled: !!event?.organizerId
    }
  });

  const { mutateAsync: updateReview, isLoading: isUpdating } = useUpdateReview({ eventId: Number(id) });
  const { mutateAsync: reviewEvent, isError } = useStartSession({ eventId: Number(id) });
  const { mutateAsync: endEventReview } = useEndSession({ eventId: Number(id) });

  const possibleEditors = [...(managers ?? []), ...(organizer?.users ?? [])?.map(({ user }) => ({ managerId: user.userId, name: user.name, username: user.username }))];

  const hasEditor = !!event?.draft?.activeEditorId || !!event?.activeEditorId;
  const isEditor = (event?.draft?.activeEditorId || event?.activeEditorId) === user?.userId;
  const activeEditor = possibleEditors?.find(manager => manager.managerId === (event?.draft?.activeEditorId ?? event?.activeEditorId));

  const form = useForm<z.infer<typeof eventReviewSchema>>({
    resolver: zodResolver(eventReviewSchema),
    mode: 'onChange',
    values: {
      notify: null,
      organizerId: (event?.draft ?? event)?.organizerId ?? 0,
      status: (event?.draft ?? event)?.draft?.status ?? (event?.draft ?? event)?.status ?? 0,
      yearId: (event?.draft ?? event)?.yearId ?? 0,
      times: (event?.draft ?? event)?.times ?? [],
      title: (event?.draft ?? event)?.title ?? '',
      description: (event?.draft ?? event)?.description ?? '',
      category: (event?.draft ?? event)?.category ?? '',
      eventType: (event?.draft ?? event)?.eventType ?? '',
      languages: typeof (event?.draft ?? event)?.languages === 'string' ? (event?.draft ?? event)?.languages?.split(';') : [],
      organizationType: (event?.draft ?? event)?.organizationType ?? '',
      topic: (event?.draft ?? event)?.topic ?? '',
      topic2: (event?.draft ?? event)?.topic2 ?? '',
      socialIssue: (event?.draft ?? event)?.socialIssue ?? '',
      eventId: (event?.draft ?? event)?.eventId ?? 0,
      locationId: (event?.draft ?? event)?.locationId ?? 0,
      locationDescription: (event?.draft ?? event)?.locationDescription ?? '',
      contactPerson1Email: (event?.draft ?? event)?.contactPerson1Email ?? '',
      contactPerson1Name: (event?.draft ?? event)?.contactPerson1Name ?? '',
      contactPerson1Telephone: (event?.draft ?? event)?.contactPerson1Telephone ?? '',
      contactPerson1Title: (event?.draft ?? event)?.contactPerson1Title ?? '',
      contactPerson2Email: (event?.draft ?? event)?.contactPerson2Email ?? '',
      contactPerson2Name: (event?.draft ?? event)?.contactPerson2Name ?? '',
      contactPerson2Telephone: (event?.draft ?? event)?.contactPerson2Telephone ?? '',
      contactPerson1Organization: (event?.draft ?? event)?.contactPerson1Organization ?? '',
      contactPerson2Organization: (event?.draft ?? event)?.contactPerson2Organization ?? '',
      showEmail: (event?.draft ?? event)?.showEmail ?? false,
      showTelephone: (event?.draft ?? event)?.showTelephone ?? false,
      url1: (event?.draft ?? event)?.url1 ?? '',
      url2: (event?.draft ?? event)?.url2 ?? '',
      url3: (event?.draft ?? event)?.url3 ?? '',
      facebookUrl: (event?.draft ?? event)?.facebookUrl ?? '',
      xurl: (event?.draft ?? event)?.xurl ?? '',
      linkedInUrl: (event?.draft ?? event)?.linkedInUrl ?? '',
      persons: (event?.draft ?? event)?.persons ?? [],
      organizers: (event?.draft ?? event)?.organizers ?? [],
      digitalStreamingType: (event?.draft ?? event)?.digitalStreamingType ?? '',
      digitalStreamingUrl: (event?.draft ?? event)?.digitalStreamingUrl ?? '',
      digitalMeetingUrl: (event?.draft ?? event)?.digitalMeetingUrl ?? '',
      digitalMeetingDescription: (event?.draft ?? event)?.digitalMeetingDescription ?? '',
      digitalArchiveUrl: (event?.draft ?? event)?.digitalArchiveUrl ?? '',
      accessibilityText: (event?.draft ?? event)?.accessibilityText ?? false,
      accessibilityTeleloop: (event?.draft ?? event)?.accessibilityTeleloop ?? false,
      accessibilityVisualInterpretation: (event?.draft ?? event)?.accessibilityVisualInterpretation ?? false,
      accessibilityWheelchairToilet: (event?.draft ?? event)?.accessibilityWheelchairToilet ?? false,
      accessibilityWheelchairVenue: (event?.draft ?? event)?.accessibilityWheelchairVenue ?? false,
      accessibilitySignLanguage: (event?.draft ?? event)?.accessibilitySignLanguage ?? false,
      environmentStationary: (event?.draft ?? event)?.environmentStationary ?? false,
      environmentPrint: (event?.draft ?? event)?.environmentPrint ?? false,
      environmentFlyer: (event?.draft ?? event)?.environmentFlyer ?? false,
      environmentBattery: (event?.draft ?? event)?.environmentBattery ?? false,
      environmentPlastic: (event?.draft ?? event)?.environmentPlastic ?? false,
      environmentRecycling: (event?.draft ?? event)?.environmentRecycling ?? false,
      environmentDisposable: (event?.draft ?? event)?.environmentDisposable ?? false,
      environmentSourceSorting: (event?.draft ?? event)?.environmentSourceSorting ?? false,
      environmentFoodEcological: (event?.draft ?? event)?.environmentFoodEcological ?? false,
      environmentFoodLocallyProduced: (event?.draft ?? event)?.environmentFoodLocallyProduced ?? false,
      environmentFoodEthical: (event?.draft ?? event)?.environmentFoodEthical ?? false,
      environmentWater: (event?.draft ?? event)?.environmentWater ?? false,
      environmentNoFood: (event?.draft ?? event)?.environmentNoFood ?? false,
      environmentServiceQuestion: (event?.draft ?? event)?.environmentServiceQuestion ?? false,
      environmentServiceElectricity: (event?.draft ?? event)?.environmentServiceElectricity ?? false,
      inserted: (event?.draft ?? event)?.inserted ?? '',
      title_lastVersion: (event?.draft ?? event)?.title_lastVersion ?? '',
      description_lastVersion: (event?.draft ?? event)?.description_lastVersion ?? '',
      socialIssue_lastVersion: (event?.draft ?? event)?.socialIssue_lastVersion ?? '',
      titleComment: '',
      descriptionComment: '',
      socialIssueComment: '',
      generalComment: '',
      noteComment: '',
      returnDue: (event?.draft ?? event)?.returnDue ?? null,
      returnDate: (event?.draft ?? event)?.returnDate ?? null,
      noCost: (event?.draft ?? event)?.noCost ?? false,
      noCostDeadline: (event?.draft ?? event)?.noCostDeadline ?? null
    }
  });

  const { proceed } = usePrompt(label('unsaved_changes_warning', LabelGroup.GLOBAL), Object.keys(form.formState.dirtyFields).length > 0 || (Object.keys(form.formState.dirtyFields).length > 0 && !form.formState.isSubmitSuccessful) || isEditor, () => isEditor && endEventReview(event?.eventId ?? 0));

  const handleSubmit = async (values: z.infer<typeof eventReviewSchema>) => {
    try {
      // If the status is changed to ActionRequired or Rejected and the event has a cost, the returnDue field must be filled out.
      if ((event?.status !== values.status || values.status === EventStatus.ActionRequired || values.status === EventStatus.Rejected) && !values?.noCost && (values.status === EventStatus.ActionRequired || values.status === EventStatus.Rejected) && values.returnDue === null) {
        return;
      }

      // If the status is changed to ActionRequired or Rejected the user must fill out the notify field. (send email to organizer)
      if (values.notify === null && (values.status === EventStatus.ActionRequired || values.status === EventStatus.Rejected || values.status === EventStatus.Approved)) {
        return;
      }

      proceed();

      const shouldNavigate = values.status !== event?.status;

      await updateReview({ ...values } as any);

      form.setValue('notify', null);
      form.setValue('descriptionComment', '');
      form.setValue('socialIssueComment', '');
      form.setValue('generalComment', '');
      form.setValue('titleComment', '');

      if (shouldNavigate) navigate(`/events#pending`);
    } catch (error) {}
  };

  const handleStartSesion = async (eventId: number) => {
    try {
      await reviewEvent(eventId);
    } catch (error) {}
  };

  useEffect(() => {
    const startScrolling = (direction: string) => {
      scrollInterval.current = setInterval(() => {
        (processingViewRef.current as any)?.scrollBy({
          top: direction === 'down' ? 80 : -80 // Adjust scroll speed here
        });
      }, 50); // Adjust interval speed here for faster/slower scrolling
    };

    const stopScrolling = () => {
      clearInterval(scrollInterval.current);
      scrollInterval.current = null;
    };

    const handleKeyDown = (event: any) => {
      if (!scrollInterval.current) {
        if (event.key === 'ArrowDown') {
          startScrolling('down');
        } else if (event.key === 'ArrowUp') {
          startScrolling('up');
        }
      }
    };

    const handleKeyUp = (event: any) => {
      if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
        stopScrolling();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      stopScrolling();
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const noteComments = comments?.filter(comment => comment.logText.field === 'note');

  const hasNoCostDeadline =
    form.watch('noCostDeadline') !== null
      ? +new Date(
          new Date((form.watch('noCostDeadline') ?? '').toString()).toLocaleDateString('sv-SE', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
          })
        ) >
        +new Date(
          new Date().toLocaleDateString('sv-SE', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
          })
        )
      : false;

  const showReturnDueDialog = !form.watch('noCost') && !hasNoCostDeadline && (form.watch('status') === EventStatus.ActionRequired || form.watch('status') === EventStatus.Rejected) && form.watch('returnDue') === null;


  if (isLoading && !isFetched) return <MangeEventSkeleton />;

  return (
    <div className="overflow-hidden max-h-[calc(100lvh)]">
      {hasEditor && !isEditor && activeEditor && isError && <ActiveEditorAlertDialog editorName={activeEditor?.name !== '' ? activeEditor?.name : activeEditor?.username ?? ''} onLockup={() => endEventReview(event?.eventId ?? 0)} isAdmin={user?.role === UserType.ADMIN} />}
      <Form {...form}>
        <form onKeyDown={event => event.key === 'Enter' && event.preventDefault()} onSubmit={form.handleSubmit(handleSubmit)}>
          {form.formState.isSubmitted && showReturnDueDialog && <ReturnDueDialog onSubmit={() => form.handleSubmit(handleSubmit)()} form={form} previousStatus={event?.status} />}

          {form.formState.isSubmitted && !showReturnDueDialog && form.watch('notify') == null && (form.watch('status') === EventStatus.ActionRequired || form.watch('status') === EventStatus.Rejected || form.watch('status') === EventStatus.Approved) && (
            <NotifyChange form={form} onSubmit={() => form.handleSubmit(handleSubmit)()} dialog={true} />
          )}

          <Tabs defaultValue={'processing'} className="w-full">
            <div className="space-y-4 p-4 px-6  bg-white border-b ">
              <div className="space-y-2">
                <div className="flex justify-between ">
                  <div className="flex items-center gap-2">
                    <span className="font-bold text-xl">
                      <Label name="overview_menu_event_title" groupName={LabelGroup.MANAGER_EVENT_HANDLING} /> {event?.draftParentId ?? event?.eventId}
                    </span>
                  </div>

                  <div className="flex gap-2 items-center">
                    <div className="w-fit">
                      <AssignManager event={event} className="w-full" />
                    </div>
                    <div className="flex gap-x-2 mt-0 items-center">
                      <FormField
                        control={form.control}
                        name="status"
                        render={({ field }) => (
                          <FormItem className="w-64">
                            <FormControl className="w-full">
                              <EventStatusDropDown disabled={!isEditor} field={field} />
                            </FormControl>
                          </FormItem>
                        )}
                      />
                      {isEditor && hasEditor ? (
                        <Button size="sm" disabled={isLoading || !form.formState.isDirty} onClick={() => form.handleSubmit(handleSubmit)()} type="submit">
                          {isUpdating && <Spinner className="mr-2 text-primary-foreground h-4 w-4" />}
                          {!isUpdating ? <Label name="update" groupName={LabelGroup.MANAGER_EVENT_HANDLING} /> : <Label name="updating" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />}
                        </Button>
                      ) : (
                        <div className="flex justify-start">
                          <Button onClick={() => event?.eventId && handleStartSesion(event?.draft?.eventId ?? event?.eventId)} type="button" className="h-9 mr-full">
                            <Label name="assign_myself_as_assigne_button_text" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <Link className="underline" to={`/organizations/${event?.organizerId ?? 0}`}>
                  {organizer?.organizerName}
                </Link>
              </div>
              <div className="flex items-center w-full justify-between">
                <TabsList>
                  <TabsTrigger value="processing">
                    <Label name="processing_tab_text" groupName={LabelGroup.ORGANIZER_EVENT_FORM} />
                  </TabsTrigger>
                  <TabsTrigger value="history">
                    <Label name="history_tab_text" groupName={LabelGroup.ORGANIZER_EVENT_FORM} />
                  </TabsTrigger>
                  <TabsTrigger value="pricing">
                    <Label name="pricing_tab_text" groupName={LabelGroup.MANAGER_EVENT_LIST} />
                  </TabsTrigger>
                  <TabsTrigger value="version_comparer" className={cn(event?.draft === null && 'hidden')} hidden={event?.draft === null}>
                    <Label name="version_comparer_tab_text" groupName={LabelGroup.MANAGER_EVENT_LIST} />
                  </TabsTrigger>
                </TabsList>
                <div className="flex gap-4">
                  {(event?.sentDate ?? event?.draft?.sentDate) && (
                    <div className="bg-sky-50 text-sky-900 text-sm flex items-center w-fit p-2 gap-2 px-2 rounded-md">
                      <Clock className="h-4 w-4  stroke-sky-500" />
                      <Label name="event_sent" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
                      <span className="font-semibold -ml-1">{formatDate(event?.sentDate ?? event?.draft?.sentDate ?? '')}</span>
                    </div>
                  )}
                  {event?.draft && (
                    <div className="bg-brand/10 text-orange-900 text-sm flex items-center w-fit p-2 gap-2 px-2 rounded-md">
                      <Files className="h-4 w-4  stroke-brand" />
                      <Label name="event_draft_version" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <TabsContent value="processing" className="pl-6 mt-0">
              {isEditor ? (
                <div className="flex gap-2">
                  <div className="w-fit  h-auto flex">
                    <OverviewMenu form={form} isFetched={isFetched} />
                  </div>
                  <div ref={processingViewRef} className="h-[calc(100lvh_-_155px)] overflow-y-auto w-full relative processing-view">
                    <div className="space-y-8 p-4">
                      {sections.map(({ id, component: Component = () => null }) => (
                        <div key={id} id={id} className={id === 'basicDetails' ? 'mt-4' : ''}>
                          <Component form={form} />
                        </div>
                      ))}
                      {isEditor ? (
                        <>
                          <FormField
                            control={form.control}
                            name="noteComment"
                            render={({ field }) => (
                              <FormItem className="bg-zinc-100 rounded-md p-4 px-4 border">
                                <FormLabel>
                                  <Label name="internal_note" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
                                </FormLabel>
                                <FormControl className="w-[300px]">
                                  <CommentField field={field} comments={noteComments ?? []} />
                                </FormControl>
                              </FormItem>
                            )}
                          />
                        </>
                      ) : (
                        <Comment comment={noteComments?.[0]} />
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="h-[calc(100lvh_-_155px)] overflow-y-auto w-full relative processing-view">
                  <div className="space-y-8 p-4 pl-0">
                    <PreviewSection />
                  </div>
                </div>
              )}
            </TabsContent>
            <TabsContent value="history" className="p-4 h-[calc(100lvh_-_155px)] overflow-y-auto w-full mt-0">
              <HistoryTimeline />
            </TabsContent>
            <TabsContent value="pricing" className="p-4">
              <Pricing />
            </TabsContent>
            <TabsContent value="organizer" className="p-4">
              <OrganizerDetails />
            </TabsContent>
            <TabsContent hidden={!event || !event?.draft} value="version_comparer" className="p-4 h-[calc(100lvh_-_155px)] overflow-y-auto w-full mt-0">
              {event && <VersionComparer original={event} />}
            </TabsContent>
          </Tabs>
        </form>
      </Form>
    </div>
  );
}
