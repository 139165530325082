import { cn } from '@/utils';
import { useEffect, useState } from 'react';
import { AlertTriangle, ArrowRight, CheckCircle } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { sections } from '../data/sections';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { editEventSchema } from '@/features/event/data/edit-event-schema';
import { useWatch } from 'react-hook-form';
import { z } from 'zod';
import { scrollToMiddle } from '@/features/event/utils/scroll-to-middle';

export function OverviewMenu({ className, items, containerRef, form, isLoading, ...props }: any) {
  const [errors, setErrors] = useState<any[]>([]);

  const { control } = form;

  const [activeSectionId, setActiveSectionId] = useState('');

  const formValues = useWatch({
    control: control,
  });

  useEffect(() => {
    if (formValues ) {
      try {
        editEventSchema.parse(formValues);
        setErrors([]);
      } catch (err) {
        if (err instanceof z.ZodError) {
          const messages = err.errors.map((error) => ({
            message: error.message,
            id: (
              error as z.ZodIssue & {
                params: { id: string };
              }
            )?.params?.id,
            section: (
              error as z.ZodIssue & {
                params: { section: string };
              }
            )?.params?.section,
          }));
          setErrors(messages);
        } else {
          console.error(err);
        }
      }
    }
  }, [formValues]);

  // Effect to update active section based on scroll position
  useEffect(() => {
    const sectionElements = sections.map((section) => document.querySelector(`#${section.id}`));

    const observer = new IntersectionObserver(
      (entries) => {
        // Filter out entries that are not intersecting
        const intersectingEntries = entries.filter((entry) => entry.isIntersecting);

        // Sort the intersecting entries by their top position (the closest to the top will be first)
        intersectingEntries.sort((a, b) => a.boundingClientRect.top - b.boundingClientRect.top);

        if (intersectingEntries.length > 0) {
          // Set the active section to the one closest to the top
          setActiveSectionId(intersectingEntries[0].target.id);
        }
      },
      {
        root: null,
        rootMargin: '0px 0px 0px 0px', // Adjust rootMargin if needed
        threshold: 0.2, // Trigger when 10% of the section is visible
      }
    );

    sectionElements.forEach((sectionElement) => {
      if (sectionElement) {
        observer.observe(sectionElement);
      }
    });

    return () => {
      // Cleanup observers when the component unmounts
      sectionElements.forEach((sectionElement) => {
        if (sectionElement) {
          observer.unobserve(sectionElement);
        }
      });
    };
  }, []);

  return (
    <div className=" max-h-[calc(100lvh_-_155px)] overflow-y-auto w-full py-4 scrollbar-hide bg-transparent">
      <div className="w-[410px] h-fit">
        <div
          className={cn(
            'flex lg:flex-col border border-zinc-200 rounded-md overflow-hidden  border-input bg-card  border-y-zinc-300 divide-y divide-zinc-300 ',
            className
          )}
          {...props}
        >
          {sections.map((section) => (
            <div
              key={section.id}
              className={cn(
                'p-4 ',

                activeSectionId === section.id ? 'bg-muted hover:bg-muted' : 'hover:bg-muted hover:underline'
              )}
            >
              <div className="flex flex-col w-full gap-4 ">
                <div className="flex items-center gap-x-2">
                  {errors?.some((error) => error.section === section.id) ? (
                    <AlertTriangle className="h-4 w-4 text-amber-500" />
                  ) : (
                    <CheckCircle className="h-4 w-4 text-success" />
                  )}
                  <div
                    onMouseDown={() => scrollToMiddle(section.id)}
                    role="button"
                    className={cn('flex items-center justify-between w-full font-medium text-sm')}
                  >
                    <Label name={'overview_menu_' + section.title} groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
                    <ArrowRight className="h-4 w-4" />
                  </div>
                </div>

                {errors?.some((error) => error.section === section.id) && (
                  <Alert className="bg-amber-50 [&>svg]:text-amber-500 text-amber-900">
                    <AlertTriangle className="h-4 w-4 " />
                    <AlertTitle className="text-sm">
                      <Label name="remedy_issues" groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
                    </AlertTitle>
                    <AlertDescription className="flex flex-col py-2">
                      <ul className="list-disc ">
                        {errors
                          ?.filter((error) => error.section === section.id)
                          .map((validation) => (
                            <div
                              className="underline"
                              key={validation.id}
                              onMouseDown={() => scrollToMiddle(validation.section)}
                            >
                              <li>
                                <Label name={validation.message} groupName={LabelGroup.MANAGER_EVENT_HANDLING} />
                              </li>
                            </div>
                          ))}
                      </ul>
                    </AlertDescription>
                  </Alert>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
