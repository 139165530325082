import { DataTable } from '@/components/ui/data-table';

import { DataTableSkeleton } from '@/components/data-table-skeleton';

import { Row } from '@tanstack/react-table';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { organizerColumns } from '../components/(organizer)/organizer-columns';
import { useAllOrganizations } from '@/features/organizer/api/get-all-organizations';
import { Organization } from '@/features/organizer/types';

export function Organizers() {
  const { data: organizers = [], isLoading } = useAllOrganizations({});

  return (
    <div className="space-y-6 flex flex-col h-full">
      <div className="flex w-full items-center justify-between">
        <div>
          <div className="flex gap-x-1 items-center">
            <h3 className="text-xl font-semibold">
              <Label name="organizers_page_title" groupName={LabelGroup.ADMIN_USER_LIST} />
            </h3>
          </div>
        </div>
      </div>
      <div className="space-y-2">
        {!isLoading ? (
          <>
            <DataTable
              columns={organizerColumns}
              data={organizers}
              config={{
                maxHeight: 'calc(100lvh - 250px)',
                getSearchFields: (row) => [
                  (row as Row<Organization>).original.organizerName,
                  (row as Row<Organization>).original?.orgNo ?? '',
                  (row as Row<Organization>).original?.invoiceOrgNo ?? '',
                  (row as Row<Organization>).original.users.join(', '),
                ],
              }}
            />
          </>
        ) : (
          <DataTableSkeleton />
        )}
      </div>
    </div>
  );
}
