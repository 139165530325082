import React, { useEffect, useState } from 'react';
import { APIProvider, ControlPosition, Map, Marker } from '@vis.gl/react-google-maps';
import { GOOGLE_MAPS_API_KEY } from '@/config';
import { Circle } from './circle';
import { Button } from '../../../../components/ui/button';
import { Check, Plus, X } from 'lucide-react';
import { FormDescription } from '../../../../components/ui/form';
import { useCreateLocation } from '@/features/location/api/createLocation';
import { AutocompleteInput } from './auto-complete-input';
import { Label } from '@/features/label/components/label';
import { UserType } from '@/features/user';
import { LabelGroup } from '@/features/label/types';
import { useMe } from '@/features/auth/api/get-me';

const API_KEY = GOOGLE_MAPS_API_KEY;

interface GoogleMapProps {
  position: { lat: number; lng: number } | null;
  onChange: (locationId: number | null) => void;
  customLocationDisabled?: boolean;
  isCustomLocation?: boolean;
}

export type AutocompleteMode = { id: string; label: string };

const DEFAULT_CENTER = { lat: 57.6397398, lng: 18.286428 };

export function GoogleMap({ position, onChange, isCustomLocation, customLocationDisabled }: GoogleMapProps) {
  const [center, setCenter] = React.useState<{ lat: number; lng: number } | null>(position || DEFAULT_CENTER);
  const [enableCustomLocation, setEnableCustomLocation] = useState(false);
  const [name, setName] = useState('');

  const { data: user } = useMe({});
  const userType = user?.role;

  const labelGroup =
    userType === UserType.ADMIN || userType === UserType.MANAGER
      ? LabelGroup.MANAGER_EVENT_HANDLING
      : LabelGroup.ORGANIZER_EVENT_FORM;

  const { mutateAsync: createLocation } = useCreateLocation({});

  const fetchAddress = async (lat: number, lng: number) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${API_KEY}`
      );
      const data = await response.json();

      const streetName = data.results[0].address_components.find((item: any) => item.types.includes('route'))?.long_name;
      const streetNumber = data.results[0].address_components.find((item: any) => item.types.includes('street_number'))?.long_name;

      return streetName && streetNumber ? `${streetName} ${streetNumber}` : '';
    } catch (error) {
      console.error(error);
      return '';
    }
  };

  const changeCenter = async (newCenter: google.maps.LatLng | null) => {
    if (!newCenter) return;

    try {
      setCenter({ lng: newCenter.lng(), lat: newCenter.lat() });
      const address = await fetchAddress(newCenter.lat(), newCenter.lng());
      setName(address);
    } catch (error) {}
  };

  const handleEnableCustomLocation = () => {
    onChange(null);
    setEnableCustomLocation(true);
  };

  const handleDisableCustomLocation = () => {
    setEnableCustomLocation(false);
  };

  const handleRemoveLocation = () => {
    onChange(null);
  };

  const handleAutoCompleteChange = (place: google.maps.places.PlaceResult | null) => {
    if (place && place.geometry?.location) {
      setCenter({ lat: place.geometry?.location.lat() ?? 0, lng: place.geometry?.location.lng() ?? 0 });

      const streetName = place.address_components?.find((component) => component.types.includes('route'))?.long_name;
      const streetNumber = place.address_components?.find((component) =>
        component.types.includes('street_number')
      )?.long_name;

      setName([streetName, streetNumber].filter((value) => value).join(' ') ?? '');
    }
  };

  const handleCustomLocation = async () => {
    try {
      const newLocation = await createLocation({
        name: name ?? 'Annan',
        latitude: (center?.lat ?? 0).toString(),
        longitude: (center?.lng ?? 0).toString(),
        category: '',
        active: true,
      });
      onChange(newLocation.locationId);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (enableCustomLocation && position) {
      setEnableCustomLocation(false);
    }
  }, [enableCustomLocation, position]);

  return (
    <div className="space-y-4">
      <APIProvider apiKey={API_KEY}>
        {!customLocationDisabled && (
          <>
            {enableCustomLocation && !isCustomLocation ? (
              <FormDescription>
                <Label name="select_new_location_description" groupName={labelGroup} />
              </FormDescription>
            ) : (
              <FormDescription>
                <Label name="create_new_location_description" groupName={labelGroup} />
              </FormDescription>
            )}
            {!customLocationDisabled && !isCustomLocation && enableCustomLocation && (
              <div className="flex gap-x-2 items-center">
                <AutocompleteInput center={center} onPlaceSelect={handleAutoCompleteChange} />
              </div>
            )}
          </>
        )}

        {isCustomLocation && (
          <div>
            <Button
              type="button"
              variant="outline"
              onClick={() => {
                handleRemoveLocation();
                handleEnableCustomLocation();
              }}
            >
              <Plus className="mr-2" size={16} />
              <Label name="create_new_location" groupName={labelGroup} />
            </Button>
          </div>
        )}
        {!customLocationDisabled && !isCustomLocation && (
          <>
            {!enableCustomLocation ? (
              <Button type="button" variant="outline" onClick={handleEnableCustomLocation}>
                <Plus className="mr-2" size={16} />
                <Label name="create_new_location" groupName={labelGroup} />
              </Button>
            ) : (
              <div className="space-x-2">
                <Button type="button" variant="outline" onClick={handleDisableCustomLocation}>
                  <X className="mr-2 text-red-500" size={16} />
                  <Label name="cancel_button_text" groupName={labelGroup} />
                </Button>
                <Button type="button" variant="outline" onClick={() => handleCustomLocation()}>
                  <Check className="mr-2 text-emerald-500" size={16} />
                  <Label name="confirm_location" groupName={labelGroup} />
                </Button>
              </div>
            )}
          </>
        )}
        <Map
          className="w-full h-96"
          defaultZoom={16}
          zoomControlOptions={{
            position: ControlPosition.RIGHT_BOTTOM,
          }}
          center={position || DEFAULT_CENTER}
          defaultCenter={position || DEFAULT_CENTER}
          gestureHandling={'greedy'}
          disableDefaultUI={true}
        >
          <Marker
            position={enableCustomLocation ? center : position || DEFAULT_CENTER}
            draggable={enableCustomLocation}
            onDrag={(e) => setCenter({ lat: e.latLng?.lat() ?? 0, lng: e.latLng?.lng() ?? 0 })}
          />
          {enableCustomLocation && (
            <Circle
              center={center}
              onCenterChanged={changeCenter}
              strokeColor={'#0c4cb3'}
              strokeOpacity={1}
              strokeWeight={3}
              fillColor={'#3b82f6'}
              fillOpacity={0.3}
              draggable
            />
          )}
        </Map>
      </APIProvider>
    </div>
  );
}
