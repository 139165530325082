import { Alert, AlertTitle } from '@/components/ui/alert';
import { EventStatus, TEvent } from '../../types';
import { Clock } from 'lucide-react';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { formatDate } from '@/utils/formatDate';

interface ReturnDueProps {
  event: TEvent;
}

export function ReturnDue({ event }: ReturnDueProps) {
  return event?.returnDate && event?.returnDue !== null && (event?.status === EventStatus.ActionRequired || event?.status === EventStatus.Rejected) && !event.noCost ? (
    <Alert className="bg-amber-50 text-amber-900  border border-amber-300 rounded-none">
      <Clock className="h-4 w-4 mt-1  stroke-amber-600" />
      <AlertTitle>
        <Label name="return_due" groupName={LabelGroup.ORGANIZER_EVENT_FORM} /> {formatDate(new Date(+new Date(event.returnDate) + (event.returnDue ?? 0) * 24 * 60 * 60 * 1000).toString()).substring(0, 11)}
      </AlertTitle>
    </Alert>
  ) : null;
}
