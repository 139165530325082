export function scrollToMiddle(sectionId: string) {
  const element = document.getElementById(sectionId);
  debugger;
  if (element) {
    const elementMiddle = element.offsetTop + element.offsetHeight / 2;
    const view = document.querySelector('.processing-view');
    view?.scrollTo({
      top: elementMiddle - (window.innerHeight / 2) * 0.8,
      behavior: 'smooth',
    });
  }
}
