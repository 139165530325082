import { PayType } from '@/features/payment/types';
import { User } from '@/features/user';

type TEvent = {
  eventId: number;
  organizerId: number;
  yearId: number;
  organizationType: string;
  status: EventStatus;
  eventType: string;
  topic: string;
  topic2: string;
  languages: string | null;
  locationId: number | null;
  locationDescription: string | null;
  category: string | null;
  title: string | null;
  title_lastVersion: string | null;
  socialIssue: string | null;
  socialIssue_lastVersion: string | null;
  description: string | null;
  description_lastVersion: string | null;
  contactPerson1Name: string | null;
  contactPerson1Title: string | null;
  contactPerson1Organization: string | null;
  contactPerson1Telephone: string | null;
  contactPerson1Email: string | null;
  contactPerson2Name: string | null;
  contactPerson2Title: string | null;
  contactPerson2Organization: string | null;
  contactPerson2Telephone: string | null;
  contactPerson2Email: string | null;
  showEmail: boolean;
  showTelephone: boolean;
  url1: string | null;
  url2: string | null;
  url3: string | null;
  facebookUrl: string | null;
  xurl: string | null;
  linkedInUrl: string | null;
  digitalStreamingType: string | null;
  digitalStreamingUrl: string | null;
  digitalMeetingUrl: string | null;
  digitalMeetingDescription: string | null;
  digitalArchiveUrl: string | null;
  accessibilityText: boolean;
  accessibilityTeleloop: boolean;
  assignedUserId?: number | null;
  accessibilityVisualInterpretation: boolean;
  accessibilityWheelchairToilet: boolean;
  accessibilityWheelchairVenue: boolean;
  accessibilitySignLanguage: boolean;
  environmentStationary: boolean;
  environmentPrint: boolean;
  environmentFlyer: boolean;
  environmentBattery: boolean;
  environmentPlastic: boolean;
  environmentRecycling: boolean;
  environmentDisposable: boolean;
  environmentSourceSorting: boolean;
  environmentFoodEcological: boolean;
  environmentFoodLocallyProduced: boolean;
  environmentFoodEthical: boolean;
  environmentWater: boolean;
  environmentNoFood: boolean;
  environmentFood: boolean;
  environmentServiceQuestion: boolean;
  environmentServiceElectricity: boolean;
  environmentServiceTravel: boolean;
  environmentServiceCooking: boolean;
  inserted: string;
  updated: string;
  logs: EventLog[];
  noCost?: boolean | null;
  organizers: EventOrganizer[];
  persons: EventPerson[];
  times: EventTime[];
  returnDue?: number | null;
  returnDate?: string | null;
  sentDate?: string | null;
  draftParentId?: number | null;
  draft: TEvent | null;
  titleComment?: string;
  descriptionComment?: string;
  socialIssueComment?: string;
  activeEditorId?: number | null;
  generalComment?: string;
  noCostDeadline?: string | null;
};

enum EventStatus {
  Draft = 1,
  AwaitingPayment = 2,
  Pending = 3,
  // Review = 4,
  ActionRequired = 5,
  Approved = 6,
  Rejected = 7,
  Cancelled = 8,
  UnPublished = 9,
  Deleted = 10,
}

type EventPerson = {
  eventId: number;
  eventPersonId: number;
  firstName: string;
  lastName: string;
  title: string;
  organizationName: string;
  party?: string;
  inserted: string;
  updated: string;
};

type EventTime = {
  eventTimeId: number;
  eventId: number;
  eventStart: string;
  eventEnd: string;
  inserted: string;
  updated: string;
};

type EventOrganizer = {
  eventId: number;
  eventOrganizerId: number;
  organizerName: string;
  inserted: string;
};

type EventLog = {
  eventLogId: number;
  eventId: number;
  category: LogCategory;
  userId: number | null;
  logText: string | null;
  inserted: string;
  user?: User;
};

type LogComment = {
  field: string;
  text: string;
};

type LogEventTime = {
  start: string;
  end: string;
  action: string;
};

type LogEventCopy = {
  from: string;
};

type LogEventPerson = {
  action: string;
  firstName: string;
  lastName: string;
  title: string;
  organizationName: string;
};

type LogEventStatus = {
  from: EventStatus;
  to: EventStatus;
};

type LogManagerAssigned = {
  username: string;
};

type LogEventUpdated = {
  field: string;
  from: string;
  to: string;
};

type LogEventOrganizer = {
  action: string;
  name: string;
};

type LogPayment = {
  amount: number;
  type: PayType;
};

type PascalToCamelCase<S extends string> = S extends `${infer T}${infer U}` ? `${Lowercase<T>}${U}` : S;

type NormalizeKeysToCamel<T> = {
  [K in keyof T as PascalToCamelCase<string & K>]: T[K] extends object ? NormalizeKeysToCamel<T[K]> : T[K];
};

type LogEventTimeCamel = NormalizeKeysToCamel<LogEventTime>;
type LogEventCopyCamel = NormalizeKeysToCamel<LogEventCopy>;
type LogEventStatusCamel = NormalizeKeysToCamel<LogEventStatus>;
type LogManagerAssignedCamel = NormalizeKeysToCamel<LogManagerAssigned>;
type LogEventUpdatedCamel = NormalizeKeysToCamel<LogEventUpdated>;
type LogEventOrganizerCamel = NormalizeKeysToCamel<LogEventOrganizer>;
type LogEventPersonCamel = NormalizeKeysToCamel<LogEventPerson>;

type EventComment = Omit<EventLog, 'logText'> & { logText: LogComment };

enum LogCategory {
  STATUS = 'Status',
  COMMENT = 'Comment',
  ASSIGNED = 'Assigned',
  ORGANIZER = 'Organizer',
  UPDATED = 'Updated',
  EVENTPERSON = 'EventPerson',
  EVENTTIME = 'EventTime',
  EVENT_COPY = 'EventCopy',
  PAYMENT = 'Payment',
  CREATED = 'Created',
  CHANGE_DRAFT = 'ChangeDraft',
  REVIEW_END = 'ReviewEnd',
  REVIEW = 'Review',
}

enum EventCategory {
  SEMINARIUM = 'Seminarium',
  HETA_STOLEN = 'Heta Stolen',
  TRAFFPUNKT = 'Träffpunkt',
}

export { EventStatus, EventCategory, LogCategory };

export type {
  TEvent,
  EventPerson,
  EventTime,
  EventOrganizer,
  EventLog,
  LogComment,
  EventComment,
  LogEventStatusCamel as LogEventStatus,
  LogManagerAssignedCamel as LogManagerAssigned,
  LogEventUpdatedCamel as LogEventUpdated,
  LogEventOrganizerCamel as LogEventOrganizer,
  LogEventCopyCamel as LogEventCopy,
  LogEventTimeCamel as LogEventTime,
  LogEventPersonCamel as LogEventPerson,
  LogPayment,
};
