import * as React from 'react';

import { cn } from '@/utils';
import { debounce } from 'lodash';

export interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  autoHeight?: boolean;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(({ className, ...props }, ref) => {
  const [value, setValue] = React.useState(props.value || null);

  const debouncedOnChange = React.useMemo(
    () =>
      debounce((event: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (props.onChange) {
          props.onChange(event);
        }
      }, 500),
    [props.onChange]
  );

  React.useEffect(() => {
    // Cleanup the debounce on unmount to prevent memory leaks
    return () => {
      debouncedOnChange.cancel();
    };
  }, [debouncedOnChange]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    setValue(newValue); // Update the UI immediately
    debouncedOnChange(event); // Debounce the side-effect
  };

  return (
    <textarea
      className={cn(
        'flex min-h-[80px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
        className
      )}
      ref={ref}
      aria-invalid={props['aria-invalid']}
      aria-describedby={props['aria-describedby']}
      name={props.name}
      rows={props.rows}
      id={props.id}
      maxLength={props.maxLength}
      // {...props}
      onInput={handleChange}
      value={value ?? props.value}
    />
  );
});
Textarea.displayName = 'Textarea';

export { Textarea };
