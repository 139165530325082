import { axios } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';
import fileSaver from 'file-saver';

export const exportInvoiceFile = async (): Promise<void> => {
  try {
    const response = await axios.get('/payment/export', { responseType: 'blob' });

    if (response instanceof Blob && response.type === 'text/plain') {
      fileSaver.saveAs(
        response,
        `Faktura_Underlag_${new Date().toLocaleDateString('sv-SE', {
          timeZone: 'Europe/Stockholm',
          day: 'numeric',
          month: 'numeric',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        })}.txt`
      );

      queryClient.invalidateQueries('invoices');
      queryClient.refetchQueries('invoices');
    } else {
      throw new Error('Unexpected response format');
    }
  } catch (err: any) {
    console.error(err.response?.data || err.message);
  }
};
