import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { FormLabel } from '@/components/ui/form';
import { label } from '@/utils/label';
import { EventPerson, EventTime } from '../../types';
import { AccessabilityIcon } from '@/components/accessability-icon';
import { useLocations } from '@/features/location/api/getLocations';
import { checkEnvironmentDiplomaQualification } from '../../utils/check-environment-diploma-qualification';
import { UserType } from '@/features/user';
import { LabelGroup } from '@/features/label/types';
import { Label } from '@/features/label/components/label';
import { useFormContext } from 'react-hook-form';
import { useMe } from '@/features/auth/api/get-me';
import { cn } from '@/utils';
import { Facebook, Linkedin, TwitterX } from 'react-bootstrap-icons';
import { Fragment } from 'react/jsx-runtime';
import { formatDate } from '@/utils/formatDate';
import { formateTime } from '@/utils/formatTime';
import { useEvent } from '../../api/getEvent';

export function PreviewSection() {
  const { data: locations } = useLocations({
    active: true
  });

  const form = useFormContext();

  const location = locations?.find(location => location.locationId === form.watch('locationId'));
  const { isQualified: isEnvironmentDiplomaApproved } = checkEnvironmentDiplomaQualification(form.getValues() as any);

  const { data: user } = useMe({});
  const { data: event } = useEvent({
    eventId: form.watch('eventId'),
    config: {
      enabled: false
    }
  });

  const userType = user?.role;

  const labelGroup = userType === UserType.ADMIN || userType === UserType.MANAGER ? LabelGroup.MANAGER_EVENT_HANDLING : LabelGroup.ORGANIZER_EVENT_FORM;

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex gap-2 text-lg font-header font-semibold">
          <Label name="event_preview_title" groupName={labelGroup} />
        </CardTitle>
      </CardHeader>
      <CardContent className={cn('text-sm space-y-2 max-w-4xl')}>
        <div className="pb-8">
          <span className="font-semibold text-3xl break-words ">{form.watch('title')}</span>
        </div>

        <div className="grid grid-cols-2 items-start gap-2 md:gap-12 w-full md:w-2/3">
          <FormLabel className="font-bold">
            <Label name="event_organizers" groupName={labelGroup} />
          </FormLabel>
          <div className="flex flex-col items-start gap-1">
            {form.watch('organizers')?.map((organizer: any) => (
              <span key={organizer.organizationName}>{organizer.organizerName}</span>
            ))}
          </div>
        </div>
        <div className="grid md:grid-cols-2 items-center gap-2 md:gap-12 w-full md:w-2/3">
          <FormLabel className="font-bold">
            <Label name="event_days" groupName={labelGroup} />
          </FormLabel>
          <div className="flex flex-col">
            {form.watch('times')?.map(
              (time: EventTime) =>
                time.eventStart &&
                time.eventEnd && (
                  <span key={time.eventTimeId}>{`${formatDate(time?.eventStart)}-
            ${formateTime(formatDate(time.eventEnd))}`}</span>
                )
            )}
          </div>
        </div>
        <div className="grid md:grid-cols-2 items-center gap-2 md:gap-12 w-full md:w-2/3">
          <FormLabel className="font-bold">
            <Label name="event_category" groupName={labelGroup} />
          </FormLabel>
          <div>{form.watch('category')}</div>
        </div>
        <div className="grid md:grid-cols-2 items-center gap-2 md:gap-12 w-full md:w-2/3">
          <FormLabel className="font-bold">
            <Label name="event_type" groupName={labelGroup} />
          </FormLabel>
          <div>{form.watch('eventType')}</div>
        </div>
        <div className="grid md:grid-cols-2 items-center gap-2 md:gap-12 w-full md:w-2/3">
          <FormLabel className="font-bold">
            <Label name="event_topic" groupName={labelGroup} />
          </FormLabel>
          <div>{form.watch('topic')}</div>
        </div>
        {form.watch('topic2') && (
          <div className="grid md:grid-cols-2 items-center gap-2 md:gap-12 w-full  md:w-2/3">
            <FormLabel className="font-bold">
              <Label name="event_topic2" groupName={labelGroup} />
            </FormLabel>
            <div>{form.watch('topic2')}</div>
          </div>
        )}
        <div className="grid md:grid-cols-2 items-center gap-2 md:gap-12 w-full md:w-2/3">
          <FormLabel className="font-bold">
            <Label name="event_languages" groupName={labelGroup} />
          </FormLabel>
          <div>
            {form
              .watch('languages')
              ?.filter((language: any) => language !== '')
              ?.map((language: any) => label(language, LabelGroup.GLOBAL))
              ?.join(', ')}
          </div>
        </div>
        <div className="grid md:grid-cols-2 items-center gap-2 md:gap-12 w-full md:w-2/3">
          <FormLabel className="font-bold">
            <Label name="event_location" groupName={labelGroup} />
          </FormLabel>
          <div>{location && form.watch('locationId') !== -100 ? location?.name : form.watch('locationId') === -100 ? <Label name="location_confirmed_later" groupName={labelGroup} /> : <div>{location?.name}</div>}</div>
        </div>
        <div className="grid md:grid-cols-2 items-center gap-2 md:gap-12 w-full md:w-2/3">
          <FormLabel className="font-bold">
            <Label name="event_location_description" groupName={labelGroup} />
          </FormLabel>
          <div>{location && form.watch('locationId') !== -100 && <div>{form.watch('locationDescription')}</div>}</div>
        </div>
        <div>
          <div className="w-full space-y-2 mt-8">
            <FormLabel className="font-bold">
              <Label name="event_social_issue" groupName={labelGroup} />
            </FormLabel>
            {form.watch('socialIssue') && <div className="break-words">{form.watch('socialIssue')}</div>}
          </div>
          <div className="w-full space-y-2 my-8">
            <FormLabel className="font-bold">
              <Label name="event_description" groupName={labelGroup} />
            </FormLabel>

            {form.watch('description') && <div className="break-words">{form.watch('description')}</div>}
          </div>
        </div>

        <div className="flex flex-col items-start gap-2 pb-4">
          <FormLabel className="w-[300px] font-bold">
            <Label name="event_participants" groupName={labelGroup} />
          </FormLabel>
          <div className="w-full flex flex-col ">
            {form.watch('persons')?.map((organizer: EventPerson) => (
              <span key={organizer.eventPersonId}>{[organizer.firstName + ' ' + organizer.lastName, organizer.title, organizer.organizationName, !organizer.party || organizer.party === 'none' ? '' : organizer.party].filter(item => item).join(', ')}</span>
            ))}
          </div>
        </div>
        {form.watch('contactPerson1Name') && (
          <div className="flex flex-col items-start gap-2 pb-4">
            <FormLabel className="w-[300px] font-bold">
              <Label name="event_contact_person1" groupName={labelGroup} />
            </FormLabel>
            <div className="w-full flex flex-wrap">
              {[form.watch('contactPerson1Name'), form.watch('contactPerson1Title'), form.watch('contactPerson1Organization'), form.watch('showTelephone') && form.watch('contactPerson1Telephone'), form.watch('showEmail') && form.watch('contactPerson1Email')]
                .filter(item => item)
                .map(item => (
                  <Fragment key={item}>
                    {item.toLowerCase().includes('@') ? (
                      <a href={`mailto:${item}`} className="underline">
                        {item}
                      </a>
                    ) : item.toLowerCase().includes('+') || item.includes('07') ? (
                      <a href={`tel:${item}`} className="underline">
                        {item}
                      </a>
                    ) : (
                      <span key={item}>{item}</span>
                    )}
                    {item && <span>, </span>}
                  </Fragment>
                ))}
            </div>
          </div>
        )}
        {form.watch('contactPerson2Name') && (
          <div className="flex flex-col items-start gap-2 pb-4">
            <FormLabel className="w-[300px] font-bold">
              <Label name="event_contact_person2" groupName={labelGroup} />
            </FormLabel>
            <div className="w-full flex flex-wrap ">
              {[form.watch('contactPerson2Name'), form.watch('contactPerson2Title'), form.watch('contactPerson2Organization'), form.watch('showTelephone') && form.watch('contactPerson2Telephone'), form.watch('showEmail') && form.watch('contactPerson2Email')]
                .filter(item => item)
                .map(item => (
                  <Fragment key={item}>
                    {item.toLowerCase().includes('@') ? (
                      <a href={`mailto:${item}`} className="underline">
                        {item}
                      </a>
                    ) : item.toLowerCase().includes('+') || item.includes('07') ? (
                      <a href={`tel:${item}`} className="underline">
                        {item}
                      </a>
                    ) : (
                      <span key={item}>{item}</span>
                    )}
                    {item && <span>, </span>}
                  </Fragment>
                ))}
            </div>
          </div>
        )}
        <div className="flex">
          <FormLabel className="font-bold w-[300px]">
            <Label name="event_accessability" groupName={labelGroup} />
          </FormLabel>
          <div className="flex w-full flex-col space-y-2">
            {form.watch('accessibilityText') && (
              <div className=" flex gap-x-2 items-center">
                <AccessabilityIcon type="text" />
                <Label name="accessibility_text" groupName={labelGroup} />
              </div>
            )}
            {form.watch('accessibilityTeleloop') && (
              <div className=" flex gap-x-2 items-center">
                <AccessabilityIcon type="teleLoop" />
                <Label name="accessibility_teleloop" groupName={labelGroup} />
              </div>
            )}
            {form.watch('accessibilityVisualInterpretation') && (
              <div className=" flex gap-x-2 items-center">
                <AccessabilityIcon type="visualInterpretation" />
                <Label name="accessibility_visual_interpretation" groupName={labelGroup} />
              </div>
            )}
            {form.watch('accessibilityWheelchairToilet') && (
              <div className=" flex gap-x-2 items-center">
                <AccessabilityIcon type="wheelchairToilet" />
                <Label name="accessibility_wheelchair_toilet" groupName={labelGroup} />
              </div>
            )}
            {form.watch('accessibilityWheelchairVenue') && (
              <div className=" flex gap-x-2 items-center">
                <AccessabilityIcon type="wheelchair" />
                <Label name="accessibility_wheelchair_venue" groupName={labelGroup} />
              </div>
            )}
            {form.watch('accessibilitySignLanguage') && (
              <div className=" flex gap-x-2 items-center">
                <AccessabilityIcon type="signLanguage" />
                <Label name="accessibility_sign_language" groupName={labelGroup} />
              </div>
            )}
            {form.watch('digitalStreamingUrl') && (
              <div className=" flex gap-x-2 items-center">
                <AccessabilityIcon type="streaming" />
                <Label name="digital_streaming" groupName={labelGroup} />
              </div>
            )}
            {isEnvironmentDiplomaApproved && (
              <div className=" flex gap-x-2 items-center">
                <AccessabilityIcon type="environmentDiploma" />
                <Label name="environment_diploma_approved" groupName={labelGroup} />
              </div>
            )}
          </div>
        </div>
        <div className="grid md:grid-cols-2 items-center gap-2 md:gap-12 w-full md:w-2/3">
          <FormLabel className="font-bold">
            <Label name="event_accessability_food" groupName={labelGroup} />
          </FormLabel>
          <div className="flex w-full flex-col space-y-6">
            <Label name={!form.watch('environmentNoFood') && (form.watch('environmentFoodEcological') || form.watch('environmentFoodLocallyProduced') || form.watch('environmentFood') || form.watch('environmentFoodEthical')) ? 'yes' : 'no'} groupName={labelGroup} />
          </div>
        </div>
        <br />
        {form.watch('url1') && (
          <div className="grid md:grid-cols-2 items-start gap-2 md:gap-12 w-full md:w-2/3">
            <FormLabel className="font-bold">
              <Label name="url1" groupName={labelGroup} />
            </FormLabel>
            <a href={form.watch('url1')} className="flex flex-col space-y-6 underline">
              {form.watch('url1')}
            </a>
          </div>
        )}
        {form.watch('url2') && (
          <div className="grid md:grid-cols-2 items-start gap-2 md:gap-12 w-full md:w-2/3">
            <FormLabel className="font-bold">
              <Label name="url2" groupName={labelGroup} />
            </FormLabel>
            <a href={form && form.watch('url2')} className="flex flex-col space-y-6 underline">
              {form.watch('url2')}
            </a>
          </div>
        )}
        {form.watch('url3') && (
          <div className="grid md:grid-cols-2 items-start gap-2 md:gap-12 w-full md:w-2/3">
            <FormLabel className="font-bold">
              <Label name="url3" groupName={labelGroup} />
            </FormLabel>
            <a href={form.watch('url3')} className="flex flex-col space-y-6 underline">
              {form.watch('url3')}
            </a>
          </div>
        )}
        <br />
        {form.watch('linkedInUrl') && (
          <div className="grid md:grid-cols-2 items-start gap-2 md:gap-12 w-full md:w-2/3">
            <FormLabel className="font-bold flex items-center gap-x-2">
              <Linkedin className="h-4 w-4" />
              <Label name="linkedInUrl" groupName={labelGroup} />
            </FormLabel>
            <a className="underline" href={form.watch('linkedInUrl')}>
              {form.watch('linkedInUrl')}
            </a>
          </div>
        )}
        {form.watch('xurl') && (
          <div className="grid md:grid-cols-2 items-start gap-2 md:gap-12 w-full md:w-2/3">
            <FormLabel className="font-bold flex items-center gap-x-2">
              <TwitterX className="h-4 w-4" />
              <Label name="xurl" groupName={labelGroup} />
            </FormLabel>
            <a className="underline" href={form.watch('xurl')}>
              {form.watch('xurl')}
            </a>
          </div>
        )}

        {form.watch('facebookUrl') && (
          <div className="grid md:grid-cols-2 items-start gap-2 md:gap-12 w-full md:w-2/3">
            <FormLabel className="font-bold flex items-center gap-x-2">
              <Facebook className="h-4 w-4" />
              <Label name="facebookUrl" groupName={labelGroup} />
            </FormLabel>
            <a className="underline" href={form.watch('facebookUrl')}>
              {form.watch('facebookUrl')}
            </a>
          </div>
        )}

        <h6 className="py-4">
          <Label name="digital_event_title" groupName={labelGroup} />
        </h6>

        {form.watch('digitalStreamingType') && (
          <div className="grid md:grid-cols-2 items-center gap-2 md:gap-12 w-full md:w-2/3">
            <FormLabel className="font-bold">
              <Label name="digital_streaming_type" groupName={labelGroup} />
            </FormLabel>
            <div className="flex flex-col space-y-6">{form.watch('digitalStreamingType')}</div>
          </div>
        )}
        {form.watch('digitalStreamingUrl') && (
          <div className="grid md:grid-cols-2 items-start gap-2 md:gap-12 w-full md:w-2/3 ">
            <FormLabel className="font-bold">
              <Label name="digital_streaming_url" groupName={labelGroup} />
            </FormLabel>
            <a href={form.watch('digitalStreamingUrl')} className="flex flex-col  underline break-all">
              {form.watch('digitalStreamingUrl')}
            </a>
          </div>
        )}
        {form.watch('digitalMeetingUrl') && (
          <div className="grid md:grid-cols-2  gap-2 md:gap-12 w-full md:w-2/3 items-start">
            <FormLabel className="font-bold">
              <Label name="digital_meeting_url" groupName={labelGroup} />
            </FormLabel>
            <a href={form.watch('digitalMeetingUrl')} className="flex flex-col underline break-all">
              {form.watch('digitalMeetingUrl')}
            </a>
          </div>
        )}
        {form.watch('digitalMeetingDescription') && (
          <div className="">
            <FormLabel className="font-bold">
              <Label name="digital_meeting_description" groupName={labelGroup} />
            </FormLabel>
            <div className="break-words">{form.watch('digitalMeetingDescription') && form.watch('digitalMeetingDescription')}</div>
          </div>
        )}
        {form.watch('digitalArchiveUrl') && (
          <div className="grid md:grid-cols-2 items-start gap-2 md:gap-12 w-full md:w-2/3">
            <FormLabel className="font-bold">
              <Label name="archive_url" groupName={labelGroup} />
            </FormLabel>
            <a href={form.watch('digitalArchiveUrl')} className="flex flex-col  underline">
              {form.watch('digitalArchiveUrl')}
            </a>
          </div>
        )}
        <div className="grid md:grid-cols-2  gap-2 md:gap-12 items-center w-full md:w-2/3">
          <FormLabel className="font-bold">
            <Label name="event_id" groupName={labelGroup} />
          </FormLabel>
          <div className="flex flex-col">{event?.draftParentId ?? event?.eventId}</div>
        </div>
      </CardContent>
    </Card>
  );
}
