import { useEffect, useRef, useState } from 'react';
import { useBeforeUnload, useBlocker } from 'react-router-dom';

export function usePrompt(
  message: string,
  show: boolean,
  callback?: () => void
): { blocker: ReturnType<typeof useBlocker>; proceed: () => void } {
  const [requestPath, setRequestPath] = useState<string | null>(null);

  const forceRef = useRef<boolean>(false);

  const blocker = useBlocker(({ currentLocation, nextLocation }) =>
    show && currentLocation.pathname !== nextLocation.pathname ? true : false
  );

  useEffect(() => {
    if (blocker.state === 'blocked') {
      if (forceRef.current === true) {
        setRequestPath(blocker.location.pathname);
        blocker.reset();
      } else {
        const confirmed = window.confirm(message);
        if (confirmed) {
          callback?.();
          window.location.pathname = blocker.location.pathname;
        }
      }
    } else {
      forceRef.current = false;
      if (requestPath) {
        callback?.();
        window.location.pathname = requestPath;
        setRequestPath(null);
      }
    }
  }, [blocker.state]);

  // Handle the browser's "beforeunload" event (e.g., closing the tab or refreshing)
  useBeforeUnload((event) => {
    if (show) {
      event.preventDefault();
      event.returnValue = '';
    }
    callback?.();
  });

  const proceed = () => {
    forceRef.current = true;
  };

  return { blocker, proceed };
}
