import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { ArrowRight } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

export function OrganizationsShortcutCard() {
  return (
    <Link to="/organizations">
      <Card className="w-full  h-full justify-between border-0 rounded-none">
        <div>
          <img
            src="https://ui.shadcn.com/placeholder.svg"
            alt="Organization placeholder"
            className="w-full h-48 object-cover"
          />
        </div>
        <CardHeader >
          <CardTitle className='flex items-center'>
            <ArrowRight className="w-6 h-6 mr-2" />
            <Label name="no_organization_created" groupName={LabelGroup.ORGANIZER_DASHBOARD} />
          </CardTitle>
        </CardHeader>
        <CardContent className="h-auto">
          <CardDescription className="text-left">
            <Label name="no_organization_created_description" groupName={LabelGroup.ORGANIZER_DASHBOARD} />
          </CardDescription>
        </CardContent>
      </Card>
    </Link>
  );
}
