import { label } from '@/utils/label';
import { DataTable } from '@/components/ui/data-table';
import { useEvents } from '../api/getEvents';
import { useState } from 'react';
import { columns } from '../components/event-columns';
import { DataTableSkeleton } from '@/components/data-table-skeleton';
import { Row } from '@tanstack/react-table';
import { ManagerEvent } from '../types';
import { EventStatus } from '@/features/event/types';
import { getKeyByValue } from '@/utils/get-key-by-value';
import { Label } from '@/features/label/components/label';
import { LabelGroup } from '@/features/label/types';
import { useLocation } from 'react-router-dom';
import { ManagerFilters } from '../components/(filters)/manager-filters';
import { Button } from '@/components/ui/button';
import { useMe } from '@/features/auth/api/get-me';
import { Badge } from '@/components/ui/badge';
import { cn } from '@/utils';

export function ManagerEvents() {
  const location = useLocation();

  const [tab, setTab] = useState<string>(location.hash ? location.hash?.replace('#', '') : 'pending');

  const { data: events = [], isLoading } = useEvents({});
  const { data: user } = useMe({});


  const sortedEvents = events
    // Separate events with sentDate from those without
    .reduce(
      (
        acc: {
          withSentDate: ManagerEvent[];
          withoutSentDate: ManagerEvent[];
        },
        event: any
      ) => {
        acc[event.sentDate ? 'withSentDate' : 'withoutSentDate'].push(event);
        return acc;
      },
      { withSentDate: [], withoutSentDate: [] }
    );

  const handleTabChange = (tab: string) => {
    setTab(tab);
    window.location.hash = tab;
  };

  const handleTabChangeOnSearch = (term: string) => {
    const currentTab = tab;

    if (currentTab === 'all') {
      return;
    }

    // if(isAdmin && currentTab !== 'all') {
    //   setTab('all');
    // }

    const filteredEvents = events?.filter(event => {
      if (term === event?.eventId?.toString()) return true;
      if (term === event?.draftParentId?.toString()) return true;
      if (term === event?.title) return true;
      if (term === event?.organizerName) return true;
      if (term === event?.organizerContactEmail) return true;
      if (term === label(getKeyByValue(EventStatus, event.status as EventStatus) ?? '', LabelGroup.MANAGER_EVENT_STATUSES)) return true;
      return false;
    });

    if (currentTab === 'changes' && !filteredEvents.some(event => !!event?.draftParentId) && filteredEvents.some(event => event.status === EventStatus.Pending)) {
      setTab('pending');
    } else if (currentTab === 'pending' && !filteredEvents.some(event => event.status === EventStatus.Pending) && filteredEvents.some(event => !!event?.draftParentId)) {
      setTab('changes');
    }
  };

  const filteredData = [
    ...sortedEvents.withSentDate.sort((a, b) => {
      const statusDiff = a.status - b.status;

      if (statusDiff !== 0) return statusDiff;

      return +new Date(a.sentDate) - +new Date(b.sentDate);
    }),
    ...sortedEvents.withoutSentDate
  ]
    // Apply the original filter
    .filter(event => {
      if (tab === 'changes') {
        return !!event?.draftParentId;
      }

      if (tab === 'pending') {
        return event.status === EventStatus.Pending;
      }

      if (tab === 'assigned') {
        return event.assignedUserId === user?.userId;
      }

      return true;
    });

  return (
    <div className="space-y-6 flex flex-col h-full">
      <div className="flex w-full items-center justify-between">
        <div>
          <div className="flex gap-x-1 items-center">
            <h3 className="text-xl font-semibold">
              <Label name="page_title" groupName={LabelGroup.MANAGER_EVENT_LIST} />
            </h3>
          </div>
        </div>
      </div>
      <div className="space-y-2">
        <div className="flex justify-between items-center">
          <div className="flex bg-zinc-200 p-1 rounded-md">
            <Button size="sm" variant="outline" className={cn('hover:bg-white', tab !== 'pending' && 'bg-zinc-200 border-0 hover:bg-transparent hover:border-transparent')} onClick={() => handleTabChange('pending')}>
              <Label name="pending_events_tab_title" groupName={LabelGroup.MANAGER_EVENT_LIST} />
              <Badge variant="default" className=" bg-teal-600 ml-2">
                {events?.filter(event => event.status === EventStatus.Pending).length}
              </Badge>
            </Button>
            <Button size="sm" variant="outline" className={cn('hover:bg-white', tab !== 'changes' && 'bg-zinc-200 border-0 hover:bg-transparent hover:border-transparent')} onClick={() => handleTabChange('changes')}>
              <Label name="changes" groupName={LabelGroup.MANAGER_EVENT_LIST} />
              {events?.filter(event => !!event?.draftParentId).length > 0 && (
                <Badge variant="default" className=" bg-orange-500 ml-2">
                  {events?.filter(event => !!event?.draftParentId).length}
                </Badge>
              )}
            </Button>
            <Button size="sm" variant="outline" className={cn('hover:bg-white', tab !== 'assigned' && 'bg-zinc-200 border-0 hover:bg-transparent hover:border-transparent')} onClick={() => handleTabChange('assigned')}>
              <Label name="my_events_tab_title" groupName={LabelGroup.MANAGER_EVENT_LIST} />
              <Badge variant="default" className=" bg-sky-600 ml-2">
                {events?.filter(event => event.assignedUserId === user?.userId).length ?? 0}
              </Badge>
            </Button>
            <Button size="sm" className={cn('hover:bg-white', tab !== 'all' && 'bg-zinc-200 border-0 hover:bg-transparent hover:border-transparent')} variant="outline" onClick={() => handleTabChange('all')}>
              <Label name="all_events_tab_title" groupName={LabelGroup.MANAGER_EVENT_LIST} />
              <Badge variant="default" className=" bg-purple-800 ml-2">
                {events?.length}
              </Badge>
            </Button>
          </div>
        </div>

        {!isLoading ? (
          <DataTable
            columns={columns}
            data={filteredData}
            columnVisibility={{
              category: false,
              organizerContactEmail: false
            }}
            config={{
              onSearchChange: handleTabChangeOnSearch,
              maxHeight: 'calc(100lvh - 290px)',
              getSearchFields: row => [
                (row as Row<ManagerEvent>).original.eventId.toString(),
                (row as Row<ManagerEvent>).original?.draftParentId?.toString() ?? '',
                (row as Row<ManagerEvent>).original.title,
                (row as Row<ManagerEvent>).original.organizerName,
                (row as Row<ManagerEvent>)?.original.organizerContactEmail ?? '',
                label(getKeyByValue(EventStatus, (row as Row<ManagerEvent>).original.status as EventStatus) ?? '', LabelGroup.MANAGER_EVENT_STATUSES)
              ]
            }}
            FacetedFilters={ManagerFilters}
          />
        ) : (
          <DataTableSkeleton />
        )}
      </div>
    </div>
  );
}
